<template>
  <label class="toggle">
    <input
      :checked="checked"
      type="checkbox"
      class="toggle__input"
      :disabled="disabled"
      @change="$emit('update:value', fieldKey, value)"
    >
    <span class="toggle__slider" />
  </label>
</template>

<script lang="ts" src="./ToggleElement.ts"></script>

<style scoped lang="scss" src="./ToggleElement.scss"></style>
