<template>
  <section class="info-blocks">
    <div class="info-blocks__header">
      <h3>Информационные блоки</h3>
      <button
        type="button"
        class="info-blocks__header-add-btn"
        @click="onOpenInfoForm()"
      >
        Добавить блок
      </button>
    </div>
    <ul class="info-blocks__list">
      <!-- @dragover.prevent="onDragOverBlock($event, item.id, item.position)" -->
      <li
        v-for="(item, index) of blocks"
        :key="item.id"
        class="info-blocks__list-item"
        draggable="true"
        @dragstart.self="onStartDragBlock($event, item.id)"
        @drop="onDropBlock($event, index)"
        @dragenter.prevent
        @dragover.prevent
      >
        <HomeInfoBlock
          :block="item"
          @editBlockClick="onOpenInfoForm"
          @addPageClick="onOpenPageForm"
          @blockRemove="onDeleteBlockBtnClick"
          @pageRemove="onDeletePageBtnClick"
        />
      </li>
    </ul>
    <Teleport to="body">
      <AppPopup
        :title="!!selectedBlock ? 'Редактировать блок' : 'Создать блок'"
        :isOpen="openedPopup.block"
        @close="onCloseInfoForm"
      >
        <HomeInfoForm :block="selectedBlock" @close="onCloseInfoForm" />
      </AppPopup>
    </Teleport>
    <Teleport to="body">
      <AppPopup
        :title="pagePopupTitle"
        :isOpen="openedPopup.page"
        @close="onClosePageForm"
      >
        <HomePageForm :type="pagePopupProps.type" @close="onClosePageForm" />
      </AppPopup>
    </Teleport>
    <Teleport to="body">
      <AppPopup
        :isOpen="openedPopup.confirm"
        title="Подтвердите действие"
        @close="onCloseConfirmPopup"
      >
        <ConfirmPopup @cancel="onCloseConfirmPopup">
          <!--@vue-ignore-->
          <template #content>
            <div class="info-blocks__confirm-content">
              <p>{{ confirmPopupContent }}</p>
              <NotifyComponent v-if="reqError" :text="reqError" />
            </div>
          </template>
        </ConfirmPopup>
      </AppPopup>
    </Teleport>
  </section>
</template>

<script lang="ts" src="./HomeInfoBlocks.ts"></script>

<style scoped lang="scss" src="./HomeInfoBlocks.scss"></style>
