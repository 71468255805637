import { defineComponent, ref, reactive, computed, onBeforeMount } from 'vue'
import EditIconBtn from '@/components/ui/EditIconBtn/EditIconBtn.vue'
import HomeBannerForm from '@/components/HomeBannerForm/HomeBannerForm.vue'
import HomeInfoBlocks from '@/components/HomeInfoBlocks/HomeInfoBlocks.vue'
import { useCompanyStore } from '@/store/company'
import {
  HOME_ELEMENTS_TABLE_HEADINGS,
  HOME_ELEMENTS_TITLES,
  HOME_AD_BANNERS_TABLE_HEADINGS,
  HOME_AD_BANNERS_TITLES,
} from '@/constants/headings'
import type { IAdBanner } from '@/types/company'

interface ConfirmPopupContent {
  text: string
  callback: '' | (() => void)
}

export default defineComponent({
  name: 'HomeView',
  components: {
    EditIconBtn,
    HomeBannerForm,
    HomeInfoBlocks,
  },
  setup() {
    const companyStore = useCompanyStore()
    const banners = computed(() => companyStore.banners ?? [])
    const elemsFields = reactive({
      largeBanners: true,
      smallBanners: false,
      loyalty: true,
      appointments: true,
      orders: true,
    })
    const selectedBanner = ref<Partial<IAdBanner> | null>(null)
    const openedPopup = reactive({
      confirm: false,
      banner: false,
    })
    const confirmPopupContent = reactive<ConfirmPopupContent>({
      text: '',
      callback: '',
    })

    const onUpdateElemsValue = (
      key: keyof typeof elemsFields,
      val: boolean,
    ) => {
      if (key === 'largeBanners') {
        elemsFields.largeBanners = val
        if (val && elemsFields.smallBanners) {
          elemsFields.smallBanners = false
        }
      } else if (key === 'smallBanners') {
        elemsFields.smallBanners = val
        if (val && elemsFields.largeBanners) {
          elemsFields.largeBanners = false
        }
      } else {
        elemsFields[key] = val
      }
    }

    const onUpdateBannersValue = (id: number, val: boolean) => {
      // req for upd
      console.log(id, val)
    }

    const openBannerForm = (id?: number) => {
      const bannerToEdit = banners.value.find((b) => b.id === id)
      if (bannerToEdit) {
        selectedBanner.value = bannerToEdit
      }
      openedPopup.banner = true
    }

    const closeBannerForm = () => {
      if (selectedBanner.value) {
        selectedBanner.value = null
      }
      openedPopup.banner = false
    }

    const handleBannerRemove = () => {
      console.log(selectedBanner.value)
      openedPopup.confirm = false
    }

    const removeBannerClick = (id: number) => {
      selectedBanner.value = {
        id,
      }
      confirmPopupContent.text =
        'Вы действительно хотите удалить рекламный баннер?'
      confirmPopupContent.callback = handleBannerRemove
      openedPopup.confirm = true
    }

    const closeConfirmPopup = () => {
      openedPopup.confirm = false
      selectedBanner.value = null
    }

    onBeforeMount(() => {
      companyStore.banners = [
        {
          id: 312111,
          name: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ',
          category: 'cat1',
          photo: {
            id: 4545,
            uuid: '',
            url: '',
            previewUrl:
              'https://images.unsplash.com/photo-1506057213367-028a17ec52e5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzOTAwNXwwfDF8c2VhcmNofDI5fHxuYXR1cmV8ZW58MHx8fHwxNzM1MjA1MzUyfDA&ixlib=rb-4.0.3&q=80&w=1080',
            originalUrl: '',
            collectionName: '',
          },
          active: true,
        },
        {
          id: 31212,
          name: '101 роза по лучшей цене',
          category: 'cat2',
          photo: {
            id: 4545,
            uuid: '',
            url: '',
            previewUrl:
              'https://images.unsplash.com/photo-1506057213367-028a17ec52e5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzOTAwNXwwfDF8c2VhcmNofDI5fHxuYXR1cmV8ZW58MHx8fHwxNzM1MjA1MzUyfDA&ixlib=rb-4.0.3&q=80&w=1080',
            originalUrl: '',
            collectionName: '',
          },
          active: false,
        },
        {
          id: 31213,
          name: '101 роза по лучшей цене!',
          category: 'cat1',
          photo: {
            id: 4545,
            uuid: '',
            url: '',
            previewUrl:
              'https://images.unsplash.com/photo-1506057213367-028a17ec52e5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzOTAwNXwwfDF8c2VhcmNofDI5fHxuYXR1cmV8ZW58MHx8fHwxNzM1MjA1MzUyfDA&ixlib=rb-4.0.3&q=80&w=1080',
            originalUrl: '',
            collectionName: '',
          },
          active: true,
        },
      ]
    })

    return {
      HOME_ELEMENTS_TABLE_HEADINGS,
      HOME_ELEMENTS_TITLES,
      HOME_AD_BANNERS_TABLE_HEADINGS,
      HOME_AD_BANNERS_TITLES,
      banners,
      elemsFields,
      selectedBanner,
      openedPopup,
      confirmPopupContent,
      onUpdateElemsValue,
      onUpdateBannersValue,
      openBannerForm,
      closeBannerForm,
      handleBannerRemove,
      removeBannerClick,
      closeConfirmPopup,
    }
  },
})
