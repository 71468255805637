import { defineComponent, computed, ref } from 'vue'
import EditIconBtn from '@/components/ui/EditIconBtn/EditIconBtn.vue'
import HomeInfoPage from '@/components/HomeInfoPage/HomeInfoPage.vue'
import {
  infoBlockCanvasOptions,
  infoBlockHeadings,
  infoBlockAddBtnTextPart,
} from '@/constants/company'
import type { PropType } from 'vue'
import type { IInfoBlock, IBlockPage } from '@/types/company'

export default defineComponent({
  name: 'HomeInfoBlock',
  components: {
    EditIconBtn,
    HomeInfoPage,
  },
  props: {
    block: {
      type: Object as PropType<IInfoBlock>,
      required: true,
    },
  },
  emits: ['editBlockClick', 'addPageClick', 'blockRemove', 'pageRemove'],
  setup(props, { emit }) {
    const isNews = computed(() => props.block.canvas === 'news')
    const getCanvasText = computed(
      () =>
        infoBlockCanvasOptions.find((i) => i.value === props.block.canvas)
          ?.label ?? '-',
    )

    const addBtnText = computed(
      () =>
        infoBlockAddBtnTextPart[
          props.block.canvas as keyof typeof infoBlockAddBtnTextPart
        ] ?? 'элемент',
    )

    const onDeletePageBtnClick = (id: number) => {
      emit('pageRemove', props.block.id, id)
    }

    return {
      infoBlockHeadings,
      infoBlockAddBtnTextPart,
      isNews,
      getCanvasText,
      addBtnText,
      onDeletePageBtnClick,
    }
  },
})
