<template>
  <div class="notify" :class="[status, size]">
    <SvgIcon :icon="status" />
    <span>{{ text }}</span>
    <button v-if="closable" type="button">
      <SvgIcon icon="x" />
    </button>
  </div>
</template>

<script lang="ts" src="./NotifyComponent.ts"></script>

<style scoped lang="scss" src="./NotifyComponent.scss"></style>
