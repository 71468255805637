<template>
  <form novalidate class="auth-form" @submit.prevent="submitHandler">
    <h1 class="auth-form__title">Добро пожаловать</h1>
    <div class="auth-form__fields">
      <fieldset
        v-for="(val, key) in fields"
        :key="key"
        class="auth-form__fields-set"
      >
        <FormElement
          :id="key"
          v-model:value="fields[key]"
          :placeholder="FIELDS_PLACEHOLDERS[key]"
          tagName="input"
          :type="fieldsTypes[key]"
          required
          autocomplete="disabled"
          :errorText="
            validationErrors && validationErrors[key]
              ? validationErrors[key]
              : null
          "
          :disabled="isCodeStep"
          :isPlaceholderStatic="false"
        />
        <button
          v-if="key === 'password' && fields.password && !isCodeStep"
          class="auth-form__fields-btn"
          type="button"
          @click="togglePassword"
        >
          <SvgIcon :icon="isPasswordShown ? 'eye-on' : 'eye-off'" />
        </button>
      </fieldset>
      <RouterLink
        v-if="!isCodeStep"
        to="/login/recovery"
        class="auth-form__link"
      >
        Забыли пароль?
      </RouterLink>
    </div>
    <CodeComponent
      v-if="isCodeStep"
      v-model:code="code"
      :phone="phone"
      :timer="timer"
      @sendAgainClick="handleSendSMS"
    />
    <div class="auth-form__btn-container">
      <NotifyComponent v-if="reqError" :text="reqError" status="error" />
      <button type="submit" :disabled="isCompleteSubmitBtnDisabled">
        {{ isCodeStep ? 'Подтвердить' : 'Войти' }}
      </button>
      <RouterLink to="/registry" class="auth-form__btn-container-registry">
        Регистрация
      </RouterLink>
    </div>
  </form>
</template>

<script lang="ts" src="./AuthForm.ts"></script>

<style scoped lang="scss" src="./AuthForm.scss"></style>
