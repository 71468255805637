import { FileSize } from '@/enum/FileSize'
import { VALIDATION_MESSAGES } from '@/constants/validationMessages'
import { validateFileExtension } from './validateFile'

export default (file: File, formats: string[]) => {
  const errors = []
  if (file.size > FileSize.companyImage) {
    errors.push(VALIDATION_MESSAGES.file.sizeValidationErr)
  }
  if (formats && !validateFileExtension(file, formats)) {
    errors.push(VALIDATION_MESSAGES.file.formatValidationErr)
  }

  return errors
}
