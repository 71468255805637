<template>
  <label class="checkbox">
    <span>{{ label }}</span>
    <input
      ref="input"
      :type="type"
      class="checkbox__input"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('update:value', fieldKey, value)"
    >
    <span
      class="checkbox__check" :class="[
        { checkbox__check_type_radio: type === 'radio' },
      ]"
    />
    <slot />
  </label>
</template>

<script lang="ts" src="./CheckboxRadioElement.ts"></script>

<style lang="scss" src="./CheckboxRadioElement.scss"></style>
