import type { DefaultError } from '@/types/httpError'
import type { Order, OrdersPayload, OrdersResponse } from '@/types/orders'
import {
  getOrdersCall,
} from '@/api/orders'
import { useApiCall } from '@/composables/useApiCall'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'

export const useOrdersStore = defineStore('orders', () => {
  const orders = ref<Order[]>([])
  const metaData = reactive({
    total: 0,
    totalPages: 0,
  })
  const filterFields = reactive({
    page: 1,
    perPage: 15,
  }) as OrdersPayload

  const {
    data: ordersData,
    executeApiCall: ordersAction,
    isLoading: ordersLoading,
    error: ordersError,
  } = useApiCall<OrdersResponse, DefaultError, OrdersPayload>(
    getOrdersCall,
    true,
  )

  const isOrdersLoading = computed(() => ordersLoading.value)

  const changePage = async (page: number) => {
    if (filterFields.page === page) {
      return
    }
    filterFields.page = page
    await getShops()
  }

  const clearFilter = async () => {
    clearQuery()
    await applyFilter(filterFields)
  }

  const applyFilter = async (filters: OrdersPayload) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key as keyof OrdersPayload] || filters[key as keyof OrdersPayload] === false) {
        filterFields[key as keyof OrdersPayload] = filters[key as keyof OrdersPayload]
      }
      else {
        filterFields[key as keyof OrdersPayload] = ''
      }
    })
    filterFields.page = 1
    await getShops()
  }

  const applySort = async (value: string | null) => {
    if (value) {
      filterFields.sort = value
    }
    else {
      delete filterFields.sort
    }
    filterFields.page = 1
    await getShops()
  }

  const getShops = async () => {
    orders.value = [
      {
        id: 1,
        number: 62728,
        date: '09:11 28.10.2024',
        client: 'Юлия',
        amount: 7073,
        status: 'Выполнен',
        isPaid: true,
      },
      {
        id: 2,
        number: 62729,
        date: '09:11 28.10.2024',
        client: 'Алексей',
        amount: 5250,
        status: 'Принят',
        isPaid: false,
      },
      {
        id: 2,
        number: 62710,
        date: '09:11 28.10.2024',
        client: 'Юлия',
        amount: 5250,
        status: 'Отменен',
        isPaid: false,
      },
    ]
    metaData.total = 10
    metaData.totalPages = 1
    // try {
    //   const filter = {} as CatalogCategoryPayload
    //   Object.keys(filterFields).forEach((key) => {
    //     if (filterFields[key as keyof CatalogCategoryPayload] || filterFields[key as keyof CatalogCategoryPayload] === false) {
    //       filter[key as keyof CatalogCategoryPayload] = filterFields[key as keyof CatalogCategoryPayload]
    //     }
    //   })
    //   await categoriesAction(filter)
    //   if (categoriesData.value) {
    //     shops.value = [...categoriesData.value.data]
    //     metaData.total = categoriesData.value.pagination.total
    //     metaData.totalPages = categoriesData.value.pagination.totalPages
    //   }
    // }
    // catch {
    //   console.error(categoriesError.value)
    // }
  }

  const clearQuery = () => {
    metaData.totalPages = 0
    metaData.total = 0
    filterFields.page = 1
    filterFields.perPage = 15
    Object.keys(filterFields).forEach(n => ['page', 'perPage'].includes(n) || delete filterFields[n])
  }

  return {
    orders,
    isOrdersLoading,
    metaData,
    filterFields,
    changePage,
    clearQuery,
    applyFilter,
    clearFilter,
    getShops,
    applySort,
  }
})
