import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppPopup',
  props: {
    title: {
      type: String,
    },
    isOpen: {
      type: Boolean,
    },
  },
  emits: ['close'],
  setup() {
    return {}
  },
})
