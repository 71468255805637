<template>
  <article class="info-block">
    <div class="info-block__header">
      <h4>{{ block.name }} (Шаблон: {{ getCanvasText }})</h4>
      <span class="info-block__status" :class="{ active: block.isActive }">{{
        block.isActive ? 'Активен' : 'Не активен'
      }}</span>
      <EditIconBtn @click="$emit('editBlockClick', block.id)" />
    </div>
    <AppTable
      v-if="!isNews"
      :headings="infoBlockHeadings"
      class="info-block__table"
    >
      <template #rows>
        <HomeInfoPage
          v-if="block.pages.length"
          v-for="page of block.pages"
          :key="page.id"
          :page="page"
          class="info-block__table-row"
          @delete="onDeletePageBtnClick"
        />
        <span v-else class="info-block__table-message">Отсутствуют</span>
      </template>
    </AppTable>
    <NotifyComponent
      v-else
      text="Новости редактируются через одноименный раздел меню"
      status="info"
      size="lg"
      class="info-block__message"
    />
    <div class="info-block__container" :class="{ 'single-child': isNews }">
      <AddBtn
        v-if="!isNews"
        @click="$emit('addPageClick', block.canvas, block.name)"
        >Добавить {{ addBtnText }}</AddBtn
      >
      <DeleteTextBtn @click="$emit('blockRemove', block.id)"
        >Удалить блок</DeleteTextBtn
      >
    </div>
  </article>
</template>

<script lang="ts" src="./HomeInfoBlock.ts"></script>

<style scoped lang="scss" src="./HomeInfoBlock.scss"></style>
