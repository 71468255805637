<template>
  <form class="company-form" @submit.prevent="handleSubmit">
    <div>
      <h3>Название</h3>
      <FormElement v-model:value="fields.name" placeholder="Введите название" />
    </div>
    <div>
      <h3>Шаблон</h3>
      <SelectElement
        :value="companyMenuItemCanvasOptions[0]"
        :options="companyMenuItemCanvasOptions"
        objectKey="canvas"
        trackBy="value"
        label="text"
        @dataChanged="onCanvasUpdate"
      />
    </div>
    <FormActionBtns
      class="company-form__btn-container"
      :isLoading="isLoading"
      :disabled="!fields.name.length"
      @cancel="$emit('close')"
    >
      Создать раздел
    </FormActionBtns>
  </form>
</template>

<script lang="ts" src="./CompanyMenuForm.ts"></script>

<style scoped lang="scss" src="./CompanyMenuForm.scss"></style>
