<template>
  <div class="catalog-filter">
    <div class="catalog-filter__wrapper">
      <MultiselectElement
        v-model="currentParent"
        :options="catalogStore.parentCategories ? catalogStore.parentCategories : []"
        label="name"
        title="Родитель"
        :allowEmpty="false"
        searchable
        :isLoading="catalogStore.parentCategoriesLoading"
        objectKey="id"
        trackBy="id"
        placeholder="Введите название"
        class="catalog-filter__select"
        :clear-on-select="false"
        :show-labels="false"
        :close-on-select="true"
        :multiple="false"
        :option-height="104"
        @update:modelValue="onMainFieldsValueUpdate"
        @search-change="searchChange"
      />
      <!--    :disabled="categoriesLoading" -->
      <div class="catalog-filter__options">
        <span class="catalog-filter__options-title">Активность</span>
        <CheckboxRadioElement
          class="catalog-filter__options-item"
          type="radio"
          label="Да"
          :value="true"
          :checked="currentActive"
          @update:value="changeActive(true)"
        />
        <CheckboxRadioElement
          class="catalog-filter__options-item"
          type="radio"
          label="Нет"
          :value="false"
          :checked="!currentActive && currentActive !== null"
          @update:value="changeActive(false)"
        />
      </div>
    </div>
    <div class="catalog-filter__actions">
      <button class="catalog-filter__actions-apply" @click="applyFilter">
        Применить
      </button>
      <button class="catalog-filter__actions-clear" @click="clearFilter">
        Очистить фильтр
      </button>
    </div>
  </div>
</template>

<script lang="ts" src="./CatalogCategoriesFilter.ts">
</script>

<style scoped lang="scss" src="./CatalogCategoriesFilter.scss"></style>
