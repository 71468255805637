<template>
  <label class="form-element">
    <span v-if="title" class="form-element__title">
      {{ title }}
    </span>
    <div class="form-element__wrapper">
      <slot name="icon" />
      <component
        :is="tagName"
        v-bind="$attrs"
        :value="value"
        :disabled="disabled"
        :maxlength="maxLength"
        class="form-element__field" :class="[
          {
            'error': errorText,
            'with-icon': $slots.icon,
            'with-label': isPlaceholderStatic
              ? false
              : !title && (focused || value),
          },
        ]"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      />
      <span
        v-if="placeholder"
        class="form-element__placeholder" :class="[
          {
            hidden: isPlaceholderStatic ? value : title && value,
            label: isPlaceholderStatic ? false : !title && (focused || value),
          },
        ]"
      >{{ placeholder }}</span>
      <span v-if="errorText" class="form-element__error">
        <div class="form-element__error-list">
          <div v-for="(error, index) in errorText" :key="index">
            <NotifyComponent :text="error" size="sm" status="error" />
          </div>
        </div>
      </span>
    </div>
  </label>
</template>

<script lang="ts" src="./FormElement.ts"></script>

<style scoped lang="scss" src="./FormElement.scss"></style>
