import { defineComponent, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import type { PropType } from 'vue'
import type { MenuItem } from '@/types/lists'
import type { MaybeElementRef } from '@vueuse/core'

export default defineComponent({
  name: 'ContextMenu',
  props: {
    items: {
      type: Array as PropType<MenuItem[]>,
    },
    position: {
      type: String,
      default: '',
      required: false,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref<boolean>(false)
    const openTop = ref<boolean>(false)
    const clickY = ref<number>(0)
    const windowHeight = ref<number>(window.innerHeight)
    const menuElemRef = ref<MaybeElementRef | null>(null)

    const close = () => {
      if (isOpen.value) {
        isOpen.value = false
      }
    }

    onClickOutside(menuElemRef, close)

    const toggleMenuHandler = (event: MouseEvent): void => {
      isOpen.value = !isOpen.value
      if (!props.position) {
        clickY.value = event.clientY

        openTop.value = windowHeight.value / 2 < clickY.value
      }
    }

    const handleClick = (functionName: string): void => {
      emit(functionName)
      close()
    }

    return {
      isOpen,
      openTop,
      menuElemRef,
      toggleMenuHandler,
      handleClick,
    }
  },
})
