import CatalogCategoriesFilter from '@/components/CatalogCategoriesFilter/CatalogCategoriesFilter.vue'
import CatalogProductsFilter from '@/components/CatalogProductsFilter/CatalogProductsFilter.vue'
import OrdersFilter from '@/components/OrdersFilter/OrdersFilter.vue'
import ShopsFilter from '@/components/ShopsFilter/ShopsFilter.vue'
import { useFilterStore } from '@/store/filter'
import { useMainStore } from '@/store/main/main'
import { type MaybeElementRef, onClickOutside } from '@vueuse/core'
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'FilterComponent',
  components: {
    CatalogCategoriesFilter,
    CatalogProductsFilter,
    ShopsFilter,
    OrdersFilter,
  },
  setup() {
    const route = useRoute()
    const filterStore = useFilterStore()
    const mainStore = useMainStore()
    const filterComponent = computed(() => `${route.name as string}Filter`)

    const filtersToggle = () => {
      filterStore.toggleFilter()
    }

    const onRemoveFilterClick = (key: string) => {
      filterStore.deleteFilterItem(key)
    }

    const closeFilter = () => {
      filterStore.isOpen = false
    }

    const sendFilter = () => {
      filterStore.isSend = true
      closeFilter()
    }
    const filterRef = ref<MaybeElementRef | null>(null)

    onClickOutside(filterRef, () => {
      closeFilter()
    })

    return {
      mainStore,
      filterStore,
      filterComponent,
      filterRef,
      sendFilter,
      filtersToggle,
      onRemoveFilterClick,
      closeFilter,
    }
  },
})
