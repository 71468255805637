import { useFilterStore } from '@/store/filter'
import { useOrdersStore } from '@/store/orders'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'CatalogProductsFilter',
  emits: ['closePopup'],
  setup(_, { emit }) {
    const client = ref<{ name: string, id: number } | null>(null)
    const currentStatus = ref<string[]>([])
    const currentAvailability = ref<null | boolean>(null)
    const priceFrom = ref('')
    const priceTo = ref('')
    const dateFrom = ref('')
    const dateTo = ref('')
    const ordersStore = useOrdersStore()
    const filterStore = useFilterStore()
    const clientOptions = [
      {
        id: '1',
        name: 'Юлия',
      },
      {
        id: '2',
        name: 'Анастасия',
      },
      {
        id: '3',
        name: 'Андрей',
      },
      {
        id: '4',
        name: 'Евгений',
      },
      {
        id: '5',
        name: 'Алексей',
      },
    ]
    const onMainFieldsValueUpdate = (value: { name: string, id: number }) => {
      filterStore.setFilterItem('client', {
        title: 'Клиент',
        name: value.name,
        value: value.id,
      })
    }

    const changeStatus = (type: string) => {
      if (currentStatus.value.includes(type)) {
        currentStatus.value = currentStatus.value.filter(item => item !== type)
      }
      else {
        currentStatus.value.push(type)
      }
      if (currentStatus.value.length) {
        filterStore.setFilterItem('status', {
          title: 'Статус',
          name: currentStatus.value.join(', '),
          value: currentStatus.value.join(','),
        })
      }
      else {
        delete filterStore.filters.selected?.status
      }
    }

    const applyFilter = async () => {
      // await ordersStore.applyFilter({
      //   search: filterStore.filters.search,
      //   active: currentActive.value !== null ? !!filterStore.filters.selected?.active?.value : '',
      //   availability: currentAvailability.value !== null ? !!filterStore.filters.selected?.availability?.value : '',
      //   basePriceFrom: filterStore.filters.selected?.basePriceFrom?.value || '',
      // })
      filterStore.isSend = false
      emit('closePopup')
    }

    const clearFilter = async () => {
      filterStore.clearFilter()
      await ordersStore.clearFilter()
    }

    watch(() => filterStore.filters?.selected?.client, (value, oldValue) => {
      if (oldValue && !value) {
        client.value = null
      }
    }, { deep: true })

    watch(() => filterStore.filters?.selected?.status, (value, oldValue) => {
      if (oldValue && !value) {
        currentStatus.value = []
      }
    }, { deep: true })

    watch(() => filterStore.isSend, async () => {
      if (filterStore.isSend) {
        await applyFilter()
      }
    })

    return {
      ordersStore,
      onMainFieldsValueUpdate,
      filterStore,
      currentStatus,
      changeStatus,
      clearFilter,
      applyFilter,
      client,
      clientOptions,
      currentAvailability,
      priceFrom,
      priceTo,
      dateFrom,
      dateTo,
    }
  },
})
