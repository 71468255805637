<template>
  <div
    ref="wrapperRef"
    class="tooltip"
    @click="isVisible = true"
    @mouseover="isVisible = true"
    @mouseleave="isVisible = false"
  >
    <div
      class="tooltip__content"
      :class="[{ tooltip__content_visible: isVisible }, colorScheme]"
    >
      <span>{{ text }}</span>
    </div>
    <slot>
      <SvgIcon icon="info" class="tooltip__icon" />
    </slot>
  </div>
</template>

<script lang="ts" src="./TooltipComponent.ts"></script>

<style scoped lang="scss" src="./TooltipComponent.scss"></style>
