<template>
  <div class="catalog-filter">
    <div class="catalog-filter__wrapper">
      <div class="catalog-filter__options">
        <span class="catalog-filter__options-title">Активность</span>
        <CheckboxRadioElement
          class="catalog-filter__options-item"
          type="radio"
          label="Да"
          :value="true"
          :checked="currentActive"
          @update:value="changeActive(true, 'active')"
        />
        <CheckboxRadioElement
          class="catalog-filter__options-item"
          type="radio"
          label="Нет"
          :value="false"
          :checked="!currentActive && currentActive !== null"
          @update:value="changeActive(false, 'active')"
        />
      </div>
      <div class="catalog-filter__options">
        <span class="catalog-filter__options-title">Наличие</span>
        <CheckboxRadioElement
          class="catalog-filter__options-item"
          type="radio"
          label="В наличии"
          :value="true"
          :checked="currentAvailability"
          @update:value="changeActive(true, 'byOrder')"
        />
        <CheckboxRadioElement
          class="catalog-filter__options-item"
          type="radio"
          label="Нет в наличии"
          :value="false"
          :checked="!currentAvailability && currentAvailability !== null"
          @update:value="changeActive(false, 'byOrder')"
        />
      </div>
      <MultiselectElement
        v-model="basePriceFrom"
        :options="priceOptions"
        label="name"
        title="Базовая цена"
        :allowEmpty="false"
        objectKey="id"
        trackBy="id"
        placeholder="Выберете стоимость"
        class="catalog-filter__select"
        :clear-on-select="false"
        :show-labels="false"
        :close-on-select="true"
        :multiple="false"
        :option-height="104"
        @update:modelValue="onMainFieldsValueUpdate"
      />
    </div>
    <div class="catalog-filter__actions">
      <button class="catalog-filter__actions-apply" @click="applyFilter">
        Применить
      </button>
      <button class="catalog-filter__actions-clear" @click="clearFilter">
        Очистить фильтр
      </button>
    </div>
  </div>
</template>

<script lang="ts" src="./CatalogProductsFilter.ts">
</script>

<style scoped lang="scss" src="./CatalogProductsFilter.scss"></style>
