<template>
  <div class="table">
    <div class="table__head">
      <div class="table__head-row">
        <div v-for="(header, index) in headings" :key="index">
          {{ header.text }}
          <!-- <span v-if="sortBy === header.value && sortDir === 'asc'"
          >&#9650;</span
        >
        <span v-if="sortBy === header.value && sortDir === 'desc'"
          >&#9660;</span
        > -->
        </div>
      </div>
    </div>
    <div class="table__body">
      <slot name="rows" />
    </div>
  </div>
</template>

<script lang="ts" src="./AppTable.ts"></script>

<style scoped lang="scss" src="./AppTable.scss"></style>
