import VueDatePicker from '@vuepic/vue-datepicker'
import { defineComponent, ref, watch } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  name: 'DatePicker',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array<string>],
      required: false,
    },
    onlyPast: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueDatePicker,
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    // range
    // model-type="yyyy.MM.dd"
    //   :clearable="false"
    //   placeholder="Выберите дату или период"
    //   :enable-time-picker="false"
    const date = ref<string | string[]>('')

    watch(
      () => date.value,
      (newDate) => {
        if (!props.disabled) {
          emit('update:value', newDate)
        }
      },
    )

    watch(
      () => props.value,
      () => {
        if (props.value) {
          date.value = props.value
        }
      },
      { immediate: true },
    )

    return { date }
  },
})
