import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TabsNav',
  props: {
    selectedTab: {
      type: String,
    },
    items: {
      type: Array as PropType<{ title: string, value: string, disabled?: boolean }[]>,
      required: true,
    },
    // круглый стиль как публикациях, flat - с полоской под табом
    isStyleFlat: {
      type: Boolean,
    },
  },
  emits: ['onTabSelect'],
  setup(_, { emit }) {
    // const selectedTab = ref('')
    const onTabClick = (value: string) => {
      // selectedTab.value = value
      emit('onTabSelect', value)
    }
    return { onTabClick }
  },
})
