<template>
  <div class="pagination" :class="[{ collapsed: UIStore.getIsMenuOpen }]">
    <div class="pagination__wrapper">
      <ul class="pagination__list">
        <li>
          <button
            :disabled="currentPage < 2 || isLoading"
            class="pagination__list-item --icon"
            @click="selectPage(currentPage - 1)"
          >
            <SvgIcon icon="chevron-left" />
          </button>
        </li>
        <li v-for="page in pagesList" :key="page">
          <button
            class="pagination__list-item" :class="[
              {
                '--current':
                  String(currentPage) === String(page),
              },
            ]"
            :disabled="String(page) === '...' || isLoading"
            @click="selectPage(Number(page))"
          >
            <span>{{ page }}</span>
          </button>
        </li>
        <li>
          <button
            :disabled="currentPage >= totalPage || isLoading"
            class="pagination__list-item --icon --right"
            @click="selectPage(currentPage + 1)"
          >
            <SvgIcon icon="chevron-left" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" src="./PaginationComponent.ts">
</script>

<style scoped lang="scss" src="./PaginationComponent.scss"></style>
