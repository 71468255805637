import { defineComponent, reactive, ref, watch, computed } from 'vue'
import LoaderWithPreview from '@/components/LoaderWithPreview/LoaderWithPreview.vue'
import validateImage from '@/helpers/validateImage'
import type { PropType } from 'vue'
import type { IAdBanner } from '@/types/company'

interface BannerFields {
  name: string
  category: {
    text: string
    value: string
  }
  photo: {
    preview: string
    value: File | null
  }
  active: boolean
}

export default defineComponent({
  name: 'HomeBannerForm',
  props: {
    banner: {
      type: Object as PropType<IAdBanner>,
    },
  },
  components: {
    LoaderWithPreview,
  },
  emits: ['close'],
  setup(props) {
    const categories = [
      {
        text: 'Категория 1',
        value: 'cat1',
      },
      {
        text: 'Категория 2',
        value: 'cat2',
      },
    ]
    const fields = reactive<BannerFields>({
      name: '',
      category: categories[0],
      photo: {
        preview: '',
        value: null,
      },
      active: true,
    })
    const selectedCategory = ref<{ text: string; value: string }>({
      text: '',
      value: '',
    })
    const bannerId = ref<string | number>('')
    const error = ref('')

    watch(
      () => props.banner,
      (newVal) => {
        if (newVal) {
          const { id, ...rest } = newVal
          bannerId.value = id
          const photo = {
            preview: rest.photo.previewUrl,
            value: null,
          }
          Object.assign(fields, {
            ...rest,
            photo,
          })
          const categoryItem = categories.find((c) => c.value === rest.category)
          if (categoryItem) {
            selectedCategory.value = categoryItem
          }
        }
      },
      { immediate: true },
    )

    const onUpdateValue = (
      key: 'active' | 'category',
      value: { text: string; value: string } | boolean,
    ) => {
      if (key === 'active') {
        fields[key] = value as boolean
        return
      }
      fields[key] = value as { text: string; value: string }
    }

    const onFileUpload = (e: Event) => {
      const fileInputData = e.target as HTMLInputElement
      if (fileInputData.files && fileInputData.files.length) {
        const file = fileInputData.files[0]
        const errors = validateImage(file, ['jpg', 'png'])
        if (errors.length) return
        const link = URL.createObjectURL(file)

        fields.photo = {
          preview: link,
          value: file,
        }
      }
    }

    const onRemovePhoto = () => {
      fields.photo = {
        preview: '',
        value: null,
      }
    }
    return {
      categories,
      fields,
      selectedCategory,
      error,
      onUpdateValue,
      onFileUpload,
      onRemovePhoto,
    }
  },
})
