export const infoBlockCanvasOptions = [
  { label: 'Акции', value: 'sales' },
  { label: 'Товарная выборка', value: 'product' },
  // { label: 'Выборка по услугам', value: 'services' },
  { label: 'Новости', value: 'news' },
]

export const infoBlockHeadings = [
  {
    text: 'Наименование страницы',
    value: 'name',
  },
  {
    text: 'Активность',
    value: 'isActive',
  },
]

export const infoBlockAddBtnTextPart = {
  sales: 'страницу',
  product: 'товар',
  //services: 'услугу',
}

export const infoBlockConfirmPopupContent = {
  block: 'Вы действительно хотите удалить информационный блок?',
  page: 'Вы действительно хотите удалить страницу?',
}
