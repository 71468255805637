<template>
  <form novalidate class="pass-recovery" @submit.prevent="handleRecoverySubmit">
    <h1 class="pass-recovery__title">
      Восстановление пароля
    </h1>
    <div v-if="!successMessageShown">
      <FormElement
        v-model:value="email"
        tagName="input"
        required
        placeholder="E-mail"
        :errorText="validationErrors?.email"
      />
      <NotifyComponent
        text="На указанный E-mail придет письмо с инструкцией"
        status="info"
        class="pass-recovery__message"
      />
    </div>
    <NotifyComponent
      v-else
      text="На указанный E-mail отправлена инструкция, используйте её для восстановления пароля"
      status="done"
      class="pass-recovery__success-message"
    />
    <div class="pass-recovery__btn-container">
      <NotifyComponent v-if="reqError" :text="reqError" status="error" />
      <button
        v-if="!successMessageShown"
        type="submit"
        :disabled="!email.length || isPasswordResetAdminLoading"
      >
        Восстановить
      </button>
      <RouterLink to="/login">
        Войти
      </RouterLink>
    </div>
  </form>
</template>

<script lang="ts" src="./PasswordRecovery.ts"></script>

<style scoped lang="scss" src="./PasswordRecovery.scss"></style>
