<template>
  <div class="load">
    <div
      v-if="preview"
      :style="{ backgroundImage: `url(${preview})` }"
      class="load__preview"
    >
      <AppLoader v-if="isLoading" class="load__spinner" />
      <button
        v-else
        class="load__preview-remove"
        type="button"
        @click="$emit('remove')"
      />
    </div>
    <FileLoader
      v-else
      :accept="accept"
      id="image"
      :text="text"
      :secondaryText="secondaryText"
      class="load__input"
      @fileLoad="(evt: Event) => $emit('load', evt)"
    />
  </div>
</template>

<script lang="ts" src="./LoaderWithPreview.ts"></script>

<style scoped lang="scss" src="./LoaderWithPreview.scss"></style>
