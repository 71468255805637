import type { PasswordResetLinkPayload } from '@/types/auth'
import type { DefaultError } from '@/types/httpError'
import { passwordResetLinkAdminApiCall } from '@/api/auth'
import { useApiCall } from '@/composables/useApiCall'
import { AUTH_ERROR_MESSAGES_BY_CODE } from '@/constants/errorMessages'
import { computed, defineComponent, ref } from 'vue'
import { RouterLink } from 'vue-router'

export default defineComponent({
  name: 'PasswordRecovery',
  components: {
    RouterLink,
  },
  setup() {
    const email = ref<string>('')
    const validationErrors = ref<{ email?: string[] } | null>(null)
    const reqError = ref<string>('')
    const successMessageShown = ref(false)

    const {
      isLoading: isPasswordResetAdminLoading,
      data: passwordResetAdminData,
      executeApiCall: passwordResetAdminAction,
      error: passwordResetError,
    } = useApiCall<'', DefaultError, PasswordResetLinkPayload>(
      passwordResetLinkAdminApiCall,
      true,
    )

    const isEmailTyped = computed(() => email.value.trim().length)
    const isEmailAnEmail = computed(() => email.value.includes('@'))

    const validateField = (field: string) => {
      if (validationErrors.value && field in validationErrors.value) {
        delete validationErrors.value[
          field as keyof typeof validationErrors.value
        ]
      }
      if (!isEmailAnEmail.value) {
        validationErrors.value = {
          email: ['Проверьте адрес электронной почты'],
        }
      }
      if (!isEmailTyped.value) {
        validationErrors.value = {
          email: ['Поле "E-mail" является обязательным'],
        }
      }
    }

    const handleRecoverySubmit = async () => {
      validationErrors.value = null
      reqError.value = ''
      validateField('email')
      if (validationErrors.value !== null) {
        return
      }

      try {
        await passwordResetAdminAction({ email: email.value })
        if (passwordResetAdminData.value) {
          successMessageShown.value = true
        }
      }
      catch {
        if (passwordResetError.value?.data) {
          if (passwordResetError.value?.data.error) {
            const errCode = passwordResetError.value.status
            reqError.value
              = errCode === 422 || errCode === 404
                ? AUTH_ERROR_MESSAGES_BY_CODE[errCode]
                : passwordResetError.value?.data.error
          }

          if (passwordResetError.value?.data.data) {
            validationErrors.value = passwordResetError.value?.data.data
          }
        }
      }
    }
    return {
      email,
      validationErrors,
      reqError,
      successMessageShown,
      isPasswordResetAdminLoading,
      handleRecoverySubmit,
    }
  },
})
