export const PASSWORD_REG_EXP =
  /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[a-z\d@$!%*#?&]{8,}$/i

export const PHONE_EXCESSIVE_CHARS_REG_EXP = /[\s+-/()]/g

export const EMAIL_REG_EXP = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/

export const PHONE_REG_EXP = /^\+7 9\d{2} \d{3}-\d{2}-\d{2}$/

export const THOUSANDS_REG_EXP = /\B(?=(\d{3})+(?!\d))/g

export const TIME_INTERVAL_REG_EXP =
  /^((?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?)-(?:\s?)(?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9]))$/

export const INN_REG_EXP = /^\d{10}$|^\d{12}$/
