<template>
  <div ref="menuElemRef" class="context-menu">
    <div class="context-menu__icon" @click="toggleMenuHandler">
      <slot name="icon">
        <SvgIcon icon="dots-vertical" />
      </slot>
    </div>
    <div
      v-show="isOpen"
      class="context-menu__wrapper"
      :class="[
        openTop || position === 'top'
          ? 'context-menu__wrapper_position_top'
          : 'context-menu__wrapper_position_bottom',
      ]"
    >
      <ul class="context-menu__items">
        <li
          v-for="i in items"
          :key="JSON.stringify(i)"
          class="context-menu__item"
          @click="i.callback ? handleClick(i.callback) : ''"
        >
          <component
            :is="i.route ? 'RouterLink' : 'span'"
            :to="i.route"
            :class="[i.color, { link: i.route }]"
          >
            {{ i.title }}
          </component>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" src="./ContextMenu.ts"></script>

<style scoped lang="scss" src="./ContextMenu.scss"></style>
