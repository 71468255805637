import AuthForm from '@/components/AuthForm/AuthForm.vue'
import PasswordRecovery from '@/components/PasswordRecovery/PasswordRecovery.vue'
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'AuthView',
  components: {
    AuthForm,
    PasswordRecovery,
  },
  setup() {
    const router = useRouter()
    const routeName = computed(() => router.currentRoute.value.name)

    const componentShown = computed(() => ({
      login: routeName.value === 'login',
      recovery: routeName.value === 'recovery',
      update: routeName.value === 'update',
    }))
    return { componentShown }
  },
})
