<template>
  <div class="content">
    <Transition name="slide">
      <MenuComponent v-if="UIStore.getIsMenuOpen" ref="menuRef" />
    </Transition>
    <header class="header" :class="[{ collapsed: UIStore.getIsMenuOpen }]">
      <button type="button" @click.capture="toggleMenu">
        <SvgIcon icon="menu" />
      </button>
      <FilterComponent v-if="isFilter" />
      <div class="header__container">
        {{ authStore.user?.name ?? 'Admin' }}
        <ContextMenu
          :items="userMenuItems"
          class="header__context-menu"
          @handleSignOut="handleSignOut"
        >
          <!--@vue-ignore-->
          <template #icon>
            <button type="button">
              <SvgIcon icon="chevron-down" />
            </button>
          </template>
        </ContextMenu>
      </div>
    </header>
    <main class="main" :class="[{ collapsed: UIStore.getIsMenuOpen }]">
      <slot />
    </main>
  </div>
</template>

<script lang="ts" src="./MainLayout.ts"></script>

<style scoped lang="scss" src="./MainLayout.scss"></style>
