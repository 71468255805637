import type { RootGetters, RootState } from '@/store/main/types'
import { Breakpoints } from '@/enum/Breakpoints'
import { defineStore } from 'pinia'

export const useMainStore = defineStore<string, RootState, RootGetters>(
  'main',
  {
    state: () => {
      return {
        windowWidth: window.innerWidth,
      }
    },

    getters: {
      isMobile: (state: RootState) => state.windowWidth < Breakpoints.TABLET,
      isTablet: (state: RootState) =>
        state.windowWidth >= Breakpoints.TABLET
        && state.windowWidth < Breakpoints.NOTEBOOK,
      isNotebook: (state: RootState) =>
        state.windowWidth >= Breakpoints.NOTEBOOK
        && state.windowWidth < Breakpoints.DESKTOP,
      isDesktop: (state: RootState) => state.windowWidth >= Breakpoints.DESKTOP,
    },
  },
)
