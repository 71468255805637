import { defineComponent, ref, reactive, watch } from 'vue'
import { infoBlockCanvasOptions } from '@/constants/company'
import type { IInfoBlock } from '@/types/company'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'HomeInfoForm',
  props: {
    block: {
      type: Object as PropType<IInfoBlock>,
    },
  },
  emits: ['close'],
  setup(props) {
    const isEditing = ref(false)
    const blockId = ref<number | ''>('')
    const fields = reactive({
      name: '',
      canvas: {
        label: '',
        value: '',
      },
      isActive: true,
    })

    const getCanvas = (value: string) =>
      infoBlockCanvasOptions.find((i) => i.value === value)

    watch(
      () => props.block,
      (newVal) => {
        if (!!newVal) {
          isEditing.value = true
          const { id, canvas, ...rest } = newVal
          blockId.value = id

          Object.assign(fields, {
            ...rest,
            canvas: getCanvas(canvas),
          })
        }
      },
      {
        immediate: true,
      },
    )

    const onValueUpdate = (
      key: keyof typeof fields,
      value: { label: string; value: string } | boolean,
    ) => {
      if (key === 'canvas' && typeof value !== 'boolean') {
        fields[key] = value
      }
      if (key === 'isActive') {
        fields[key] = value as boolean
      }

      console.log(fields)
    }

    return {
      isEditing,
      fields,
      infoBlockCanvasOptions,
      onValueUpdate,
    }
  },
})
