<template>
  <form class="info-form" @submit.prevent>
    <fieldset class="info-form__fieldset">
      <div class="info-form__field">
        <span>Наименование информационного блока</span>
        <FormElement
          v-model:value="fields.name"
          placeholder="Введите наименование"
        />
      </div>
      <div v-if="!isEditing" class="info-form__field">
        <span>Используемый шаблон</span>
        <SelectElement
          :value="infoBlockCanvasOptions[0]"
          :options="infoBlockCanvasOptions"
          objectKey="canvas"
          label="label"
          trackBy="label"
          @dataChanged="onValueUpdate"
        />
      </div>
      <CheckboxRadioElement
        label="Активен"
        fieldKey="isActive"
        :value="!fields.isActive"
        :checked="fields.isActive"
        @update:value="onValueUpdate"
      />
    </fieldset>
    <FormActionBtns reversed @cancel="$emit('close')">{{
      isEditing ? 'Сохранить  изменения' : 'Создать блок'
    }}</FormActionBtns>
  </form>
</template>

<script lang="ts" src="./HomeInfoForm.ts"></script>

<style scoped lang="scss" src="./HomeInfoForm.scss"></style>
