<template>
  <div class="image-load">
    <p v-if="heading" class="image-load__title">
      {{ heading }}
    </p>
    <slot name="info" />
    <TransitionGroup tag="ul" name="list" class="image-load__list">
      <li v-for="{ id, preview, isLoading, serverId } of images" :key="id">
        <div
          v-if="preview"
          :style="{ backgroundImage: `url(${preview})` }"
          class="image-load__list-preview"
        >
          <AppLoader v-if="isLoading" class="image-load__loader" />
          <button
            v-if="!isLoading"
            type="button"
            @click="$emit('remove', groupKey, serverId || id)"
          />
        </div>
        <FileLoader
          v-else
          :id="groupKey"
          accept="image/*"
          :text="fileLoaderText ?? 'Загрузите изображение'"
          :secondaryText="fileLoaderSecondaryText"
          class="image-load__loader"
          @fileLoad="(evt: Event) => $emit('load', evt, id)"
        />
      </li>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" src="./AppImageLoad.ts"></script>

<style scoped lang="scss" src="./AppImageLoad.scss"></style>
