import { defineComponent } from 'vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default defineComponent({
  name: 'MultiselectElement',
  components: {
    Multiselect,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hiddenValue: {
      type: Boolean,
      default: false,
    },
    errorText: { type: Array, required: false },
  },

  setup(props, { emit }) {
    return { }
  },
})
