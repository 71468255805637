import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppTable',
  props: {
    headings: {
      type: Array as PropType<{ text: string, value: string }[]>,
      required: true,
    },
  },
  emits: ['changeSort'],
  setup(_, { emit }) {
    const onChangeSort = (key: string, value: string) => {
      emit('changeSort', key, value)
    }

    return { onChangeSort }
  },
})
