<template>
  <section class="home">
    <section class="home__wrapper">
      <h1>Отображение элементов</h1>
      <AppTable
        :headings="HOME_ELEMENTS_TABLE_HEADINGS"
        class="home__table home__table_type_elems"
      >
        <template #rows>
          <div
            v-for="(val, key) of elemsFields"
            :key="key"
            class="home__table-row-elems"
          >
            <div class="home__table-container">
              <span>Элемент</span>
              {{ HOME_ELEMENTS_TITLES[key] }}
            </div>
            <div class="home__table-container">
              <span>Активность</span>
              <div class="home__table-row-elems--wrapper">
                <ToggleElement
                  :value="!val"
                  :checked="val"
                  :fieldKey="key"
                  @update:value="onUpdateElemsValue"
                />
                <TooltipComponent
                  v-if="key === 'smallBanners'"
                  text="Можно выбрать только один вид слайдера, либо ни одного"
                  class="home__table-row-elems--tip"
                />
              </div>
            </div>
          </div>
        </template>
      </AppTable>
    </section>
    <section class="home__wrapper">
      <h2>Рекламные баннеры</h2>
      <AppTable
        :headings="HOME_AD_BANNERS_TABLE_HEADINGS"
        class="home__table_type_ad"
      >
        <template #rows>
          <div v-if="banners.length">
            <div
              v-for="{ id, name, category, photo, active } in banners"
              :key="id"
              class="home__table-row-banners"
            >
              <div class="home__table-container">
                <span>Наименование</span>
                <p>{{ name }}</p>
              </div>
              <div class="home__table-container">
                <span>Категория</span>
                <p>{{ category }}</p>
              </div>
              <div class="home__table-container">
                <span>Фото</span>
                <img
                  :src="photo.previewUrl"
                  :img="`Изображение ${name}`"
                  class="home__table-row-banners--img"
                />
              </div>
              <div class="home__table-container">
                <span>Активность</span>
                <ToggleElement
                  :value="!active"
                  :checked="active"
                  fieldKey="active"
                  @update:value="onUpdateBannersValue(id, active)"
                />
              </div>
              <div class="home__table-row-banners--btn-container">
                <EditIconBtn @click="openBannerForm(id)" />
                <DeleteIconBtn @click="removeBannerClick(id)" />
              </div>
            </div>
          </div>
          <span v-else class="home__message-error">Отсутствуют</span>
        </template>
      </AppTable>
      <AddBtn class="home__add-btn" @click="openBannerForm"
        >Добавить баннер</AddBtn
      >
    </section>
    <HomeInfoBlocks />
    <Teleport to="body">
      <AppPopup
        :title="!!selectedBanner ? 'Редактировать баннер' : 'Создать баннер'"
        :isOpen="openedPopup.banner"
        @close="closeBannerForm"
      >
        <HomeBannerForm :banner="selectedBanner" @close="closeBannerForm" />
      </AppPopup>
    </Teleport>
    <Teleport to="body">
      <AppPopup
        title="Подтвердите действие"
        :isOpen="openedPopup.confirm"
        @close="closeConfirmPopup"
      >
        <ConfirmPopup
          @agree="
            typeof confirmPopupContent.callback === 'function'
              ? confirmPopupContent.callback()
              : ''
          "
          @cancel="closeConfirmPopup"
        >
          <!--@vue-expect-error-->
          <template #content>
            <p>{{ confirmPopupContent.text }}</p>
          </template>
        </ConfirmPopup>
      </AppPopup>
    </Teleport>
  </section>
</template>

<script lang="ts" src="./HomeView.ts"></script>

<style scoped lang="scss" src="./HomeView.scss"></style>
