import { defineStore } from 'pinia'
import { useApiCall } from '@/composables/useApiCall'
import {
  getProfileApiCall,
  getProfileMenuItemsIconsListApiCall,
  getProfileMenuItemsListApiCall,
  createProfileMenuItemApiCall,
  editProfileMenuItemApiCall,
  deleteProfileMenuItemApiCall,
} from '@/api/company'
import type { RootState, RootGetters, RootActions } from './types'
import type { DefaultError } from '@/types/httpError'
import type {
  ICompanyProfileMenuSection,
  ICompanyMenuSection,
  GetCompanyProfileMenuSectionsIconsResponse,
  GetCompanyProfileMenuSectionsResponse,
  CreateCompanyProfileMenuSectionPayload,
  CreateEditCompanyProfileMenuSectionResponse,
  EditCompanyProfileMenuSectionPayload,
} from '@/types/company'
import type { DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'

export const useCompanyStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('company', {
  state() {
    return {
      companyData: {
        email: 'name@domain.ru',
        phone: '+79886787855',
        links: {
          vk: 'https://learn.microsoft.com/',
          insta: 'https://learn.microsoft.com/',
          tg: 'https://learn.microsoft.com/',
          facebook: 'https://learn.microsoft.com/',
        },
        image: {
          id: 1,
          uuid: '',
          originalUrl: '',
          previewUrl:
            'https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzOTAwNXwwfDF8c2VhcmNofDF8fGNpdHl8ZW58MHx8fHwxNzMzMjk3NDgwfDA&ixlib=rb-4.0.3&q=80&w=1080',
          collectionName: '',
          url: '',
        },
      },
      profileSectionIcons: null,
      profileSections: null,
      menuSections: null,
      selectedMenuSection: null,
      banners: null,
      error: null,
    }
  },
  getters: {
    getSection(state: RootState) {
      return (id: number) => state.profileSections?.find((i) => i.id === id)
    },
  },
  actions: {
    setNewProfileSection(body: ICompanyProfileMenuSection) {
      if (this.profileSections) {
        this.profileSections.push(body)
      }
    },
    replaceProfileSection(id: number, body?: ICompanyProfileMenuSection) {
      if (this.profileSections) {
        if (body) {
          const index = this.profileSections.findIndex((i) => i.id === id)
          this.profileSections[index] = body
        } else {
          this.profileSections = this.profileSections.filter((i) => i.id !== id)
        }
      }
    },
    replaceMenuSection(id: number, body?: ICompanyMenuSection) {
      if (this.menuSections) {
        if (body) {
          const index = this.menuSections.findIndex((i) => i.id === id)
          this.menuSections[index] = body
        } else {
          this.menuSections = this.menuSections.filter((i) => i.id !== id)
        }
      }
    },
    async getProfile() {
      const { data, executeApiCall, error } = useApiCall(
        getProfileApiCall,
        true,
      )

      try {
        await executeApiCall()
        if (data.value) {
          console.log(data.value)
        }
      } catch {}
    },
    async getProfileSectionIcons() {
      const {
        data: menuItemsIconsData,
        executeApiCall: getMenuItemsIconsList,
        error: menuItemsIconsError,
      } = useApiCall<GetCompanyProfileMenuSectionsIconsResponse, DefaultError>(
        getProfileMenuItemsIconsListApiCall,
        true,
      )

      try {
        await getMenuItemsIconsList()
        if (menuItemsIconsData.value) {
          this.profileSectionIcons = menuItemsIconsData.value.data
        }
      } catch {
        if (menuItemsIconsError.value) {
          console.error(menuItemsIconsError.value.data.error)
        }
      }
    },
    async getProfileSections() {
      const {
        data: menuItemsData,
        executeApiCall: getMenuItemsList,
        error: menuItemsError,
      } = useApiCall<GetCompanyProfileMenuSectionsResponse, DefaultError>(
        getProfileMenuItemsListApiCall,
        true,
      )

      try {
        await getMenuItemsList()
        if (menuItemsData.value) {
          this.profileSections = menuItemsData.value.data
        }
      } catch {
        if (menuItemsError.value) {
          console.error(menuItemsError.value.data.error)
        }
      }
    },
    async addProfileSection(params: CreateCompanyProfileMenuSectionPayload) {
      const {
        data,
        executeApiCall,
        error: profileSectionError,
      } = useApiCall<
        CreateEditCompanyProfileMenuSectionResponse,
        DefaultError,
        CreateCompanyProfileMenuSectionPayload
      >(createProfileMenuItemApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          this.setNewProfileSection(data.value.data)
        }
      } catch (error) {
        if (profileSectionError.value) {
          this.error = profileSectionError.value.data
        }
      }
    },
    async editProfileSection(params?: EditCompanyProfileMenuSectionPayload) {
      const {
        data,
        executeApiCall,
        error: profileSectionError,
      } = useApiCall<
        CreateEditCompanyProfileMenuSectionResponse,
        DefaultError,
        EditCompanyProfileMenuSectionPayload
      >(editProfileMenuItemApiCall, true, params)

      try {
        await executeApiCall()
        if (data.value) {
          const menuItem = data.value.data
          this.replaceProfileSection(menuItem.id, menuItem)
        }
      } catch (error) {
        if (profileSectionError.value) {
          this.error = profileSectionError.value.data
        }
      }
    },
    async deleteProfileSection(params: DefaultDeletePayload) {
      const {
        data,
        executeApiCall,
        error: deleteProfileSectionError,
      } = useApiCall<DeleteSuccessResponse, DefaultError, DefaultDeletePayload>(
        deleteProfileMenuItemApiCall,
        true,
        params,
      )

      try {
        await executeApiCall()
        if (data.value) {
          this.replaceProfileSection(params.id)
        }
      } catch (error) {
        if (deleteProfileSectionError.value) {
          this.error = deleteProfileSectionError.value.data
        }
      }
    },
  },
})
