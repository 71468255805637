<template>
  <div class="form-action-btns" :class="{ reversed }">
    <button type="submit" :disabled="isLoading || disabled">
      <Transition>
        <AppLoader v-if="isLoading" />
      </Transition>
      <slot> Сохранить изменения </slot>
    </button>
    <button type="button" @click="$emit('cancel')">Отмена</button>
  </div>
</template>

<script lang="ts" src="./FormActionBtns.ts"></script>

<style scoped lang="scss" src="./FormActionBtns.scss"></style>
