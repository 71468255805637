import axios from 'axios'
import type {
  GetCompanyProfileMenuSectionsIconsResponse,
  ICompanyProfileMenuSection,
  GetCompanyProfileMenuSectionsResponse,
  CreateCompanyProfileMenuSectionPayload,
  CreateEditCompanyProfileMenuSectionResponse,
  EditCompanyProfileMenuSectionPayload,
} from '@/types/company'
import type { DefaultDeletePayload, DeleteSuccessResponse } from '@/types/main'

export const getProfileApiCall = async (abortController?: AbortController) => {
  const { data } = await axios.get('/dashboard/v1/chief/profile/me', {
    signal: abortController?.signal,
  })
  return data
}

export const getProfileMenuItemsIconsListApiCall = async (
  abortController?: AbortController,
): Promise<GetCompanyProfileMenuSectionsIconsResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/menu-items/icons', {
    signal: abortController?.signal,
  })
  return data
}

export const getProfileMenuItemsListApiCall = async (
  abortController?: AbortController,
): Promise<GetCompanyProfileMenuSectionsResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/menu-items', {
    signal: abortController?.signal,
  })
  return data
}

export const getProfileMenuItemApiCall = async (
  abortController?: AbortController,
  params?: ICompanyProfileMenuSection['id'],
) => {
  const { data } = await axios.get(`/dashboard/v1/chief/menu-items/${params}`, {
    signal: abortController?.signal,
  })
  return data
}

export const createProfileMenuItemApiCall = async (
  abortController?: AbortController,
  params?: CreateCompanyProfileMenuSectionPayload,
): Promise<CreateEditCompanyProfileMenuSectionResponse> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/menu-items',
    {
      ...params,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const editProfileMenuItemApiCall = async (
  abortController?: AbortController,
  params?: EditCompanyProfileMenuSectionPayload,
): Promise<CreateEditCompanyProfileMenuSectionResponse> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/menu-items/${params?.id}`,
    {
      ...params?.data,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteProfileMenuItemApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/menu-items/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}
