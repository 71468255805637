<template>
  <aside class="menu">
    <section class="border-bottom menu__header">Logo</section>
    <nav>
      <ul class="border-bottom menu__list">
        <li v-for="item of menuItems.top" :key="item.path" class="">
          <RouterLink
            v-if="!item.children"
            :to="item.path"
            class="menu__list-item"
          >
            <SvgIcon :icon="item.icon" />
            <p>{{ item.title }}</p>
          </RouterLink>
          <div v-else class="menu__list-select">
            <component
              :is="item.path === '/company' ? 'RouterLink' : 'span'"
              :to="item.path"
              class="menu__list-item"
              :class="[{ 'router-link-active': isSelect(item.path) }]"
              @click="openChildren(item.icon)"
            >
              <SvgIcon :icon="item.icon" />
              <p>{{ item.title }}</p>
              <SvgIcon
                :icon="isOpen(item.icon) ? 'chevron-up' : 'chevron-down'"
                class="menu__list-item-chevron"
                @click.stop.prevent="openChildren(item.icon)"
              />
            </component>
            <div v-if="isOpen(item.icon)">
              <div class="menu__list-select-children">
                <div v-if="item.path !== '/company'">
                  <RouterLink
                    v-for="children in item.children"
                    :key="children.title"
                    :to="item.path + children.path"
                    class="menu__list-select-child"
                  >
                    <p>{{ children.title }}</p>
                  </RouterLink>
                </div>
                <ul v-else>
                  <li
                    v-for="{ id, name } in companySectionsMenu"
                    :key="id"
                    class="menu__list-select-child menu__list-select-child_li"
                    :class="{
                      'router-link-active': isSelect(`/company/${id}`),
                    }"
                  >
                    <RouterLink
                      :to="`${item.path}/${id}`"
                      class="menu__list-select-text"
                    >
                      {{ name }}
                    </RouterLink>
                    <DeleteIconBtn
                      class="menu__list-select-btn"
                      @click.stop="onDeleteCompanyMenuItem(id)"
                    />
                  </li>
                </ul>
              </div>
              <AddBtn
                v-if="item.path === '/company'"
                class="menu__list-select-add"
                @click="toggleIsCompanyMenuItemFormOpen"
                ><span> Добавить раздел </span>
              </AddBtn>
            </div>
          </div>
        </li>
      </ul>
      <ul class="menu__list">
        <li v-for="{ icon, title, path } of menuItems.bottom" :key="path">
          <RouterLink :to="path" class="menu__list-item">
            <SvgIcon :icon="icon" />
            <p>{{ title }}</p>
          </RouterLink>
        </li>
      </ul>
    </nav>
    <Teleport to="body">
      <AppPopup
        :isOpen="isConfirmPopupOpen"
        title="Подтвердите действие"
        @close="onConfirmPopupClose"
      >
        <ConfirmPopup
          :isLoading="isReqLoading"
          @cancel="onConfirmPopupClose"
          @agree="handleCompanyMenuItemDelete"
        >
          <!--@vue-ignore-->
          <template #content>
            <div class="profile__content-confirm">
              <p>Вы действительно хотите удалить раздел?</p>
              <NotifyComponent
                v-if="reqError"
                :text="reqError"
                status="error"
              />
            </div>
          </template>
          <!--@vue-ignore-->
          <template #agree> Да, удалить </template>
        </ConfirmPopup>
      </AppPopup>
      <AppPopup
        :isOpen="isCompanyMenuItemFormOpen"
        title="Создать раздел"
        @close="toggleIsCompanyMenuItemFormOpen"
      >
        <CompanyMenuForm
          :isLoading="isReqLoading"
          @close="toggleIsCompanyMenuItemFormOpen"
        />
      </AppPopup>
    </Teleport>
  </aside>
</template>

<script lang="ts" src="./MenuComponent.ts"></script>

<style scoped lang="scss" src="./MenuComponent.scss"></style>
