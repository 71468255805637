import AuthView from '@/views/AuthView/AuthView.vue'
import HomeView from '@/views/HomeView/HomeView.vue'
import RegistryView from '@/views/RegistryView/RegistryView.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useCompanyStore } from '@/store/company'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, top: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/builder',
      name: 'builder',
      component: () => import('@/views/BuilderView/BuilderView.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/shops',
      name: 'shops',
      component: () => import('@/views/ShopsPage/ShopsPage.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/shops/:id',
      name: 'shopsItem',
      component: () => import('@/views/ShopItemPage/ShopItemPage.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/shops/create',
      name: 'shopsItemCreate',
      component: () => import('@/views/ShopItemPage/ShopItemPage.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/OrdersPage/OrdersPage.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/views/ClientsPage/ClientsPage.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/orders/:id',
      name: 'ordersItem',
      component: () => import('@/views/OrdersPage/OrdersPage.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/catalog/categories/create',
      name: 'catalogCategoryCreate',
      component: () => import('@/views/CatalogCategory/CatalogCategory.vue'),
    },
    {
      path: '/catalog/categories/:id',
      name: 'catalogCategory',
      component: () => import('@/views/CatalogCategory/CatalogCategory.vue'),
    },
    {
      path: '/catalog/products/create',
      name: 'catalogProductCreate',
      component: () => import('@/views/CatalogProduct/CatalogProduct.vue'),
    },
    {
      path: '/catalog/products/:id',
      name: 'catalogProduct',
      component: () => import('@/views/CatalogProduct/CatalogProduct.vue'),
    },
    {
      path: '/catalog',
      component: () => import('@/views/CatalogPage/CatalogPage.vue'),
      meta: {
        layout: 'Main',
      },
      children: [
        {
          path: 'categories',
          name: 'catalogCategories',
          component: () =>
            import('@/components/CatalogCategories/CatalogCategories.vue'),
        },
        {
          path: 'kits',
          name: 'catalogKits',
          component: () => import('@/components/CatalogKits/CatalogKits.vue'),
          meta: {
            layout: 'Main',
          },
        },
        {
          path: 'products',
          name: 'catalogProducts',
          component: () =>
            import('@/components/CatalogProducts/CatalogProducts.vue'),
          meta: {
            layout: 'Main',
          },
        },
      ],
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/ProfileView/ProfileView.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/company',
      component: () => import('@/views/CompanyView/CompanyView.vue'),
      meta: {
        layout: 'Main',
      },
    },
    {
      path: '/company/:id',
      name: 'menuSection',
      component: () =>
        import('@/components/CompanyMenuComponent/CompanyMenuComponent.vue'),
      meta: {
        layout: 'Main',
      },
      beforeEnter(to, from, next) {
        const companyStore = useCompanyStore()
        const companyMenuSections = companyStore.menuSections
        const id = Number(to.params.id)
        // req for data
        // temp for demo
        const section = companyMenuSections?.find((i) => i.id === id)
        if (section) {
          companyStore.selectedMenuSection = section
          next()
        } else next(from)
      },
    },
    {
      path: '/settings',
      component: () => import('@/views/SettingsView/SettingsView.vue'),
      meta: {
        layout: 'Main',
      },
      children: [
        {
          path: '',
          name: 'mainSettings',
          component: () => import('@/components/MainSettings/MainSettings.vue'),
        },
        {
          path: 'store',
          name: 'storeSettings',
          component: () =>
            import('@/components/StoreSettings/StoreSettings.vue'),
          meta: {
            layout: 'Main',
            tab: 'store',
          },
        },
        {
          path: 'pay-delivery',
          name: 'payDeliverySettings',
          component: () =>
            import('@/components/PayDeliverySettings/PayDeliverySettings.vue'),
          meta: {
            layout: 'Main',
            tab: 'pay-delivery',
          },
        },
        {
          path: 'bottom-navbar',
          name: 'bottomNavBarSettings',
          component: () =>
            import(
              '@/components/BottomNavBarSettings/BottomNavBarSettings.vue'
            ),
          meta: {
            layout: 'Main',
            tab: 'bottom-navbar',
          },
        },
      ],
    },
    {
      path: '/login',
      meta: {
        layout: 'Auth',
      },
      children: [
        {
          path: '',
          name: 'login',
          component: AuthView,
        },
        {
          path: 'recovery',
          name: 'recovery',
          component: AuthView,
        },
      ],
    },
    {
      path: '/registry',
      name: 'registry',
      component: RegistryView,
      meta: {
        layout: 'Auth',
      },
    },
  ],
})

export default router
