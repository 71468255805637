import type {
  ShopsItemResponse,
  ShopsPayload,
  ShopsResponse,
} from '@/types/shops'
import type { ItemPayload } from '../types/main'
import axios from 'axios'

const getShopsCall = async (
  abortController?: AbortController,
  params?: ShopsPayload,
): Promise<ShopsResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/shops', {
    signal: abortController?.signal,
    params,
  })
  return data.data
}

const getShopsItemCall = async (
  abortController?: AbortController,
  params?: ItemPayload,
): Promise<ShopsItemResponse> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/shops/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

export { getShopsCall, getShopsItemCall }
