import { defineComponent, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import type { PropType } from 'vue'
import type { MaybeElementRef } from '@vueuse/core'

export default defineComponent({
  name: 'TooltipComponent',
  props: {
    text: {
      type: String,
      required: true,
    },
    colorScheme: {
      type: String as PropType<'light' | 'dark'>,
      default: 'dark',
    },
  },
  setup() {
    const isVisible = ref(false)
    const wrapperRef = ref<MaybeElementRef | null>(null)

    onClickOutside(wrapperRef, () => {
      if (isVisible.value) {
        isVisible.value = false
      }
    })
    return { isVisible, wrapperRef }
  },
})
