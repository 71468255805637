import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ToggleElement',
  emits: ['update:value'],
  props: {
    value: {
      type: [String, Boolean],
    },
    disabled: {
      type: Boolean,
    },
    checked: {
      type: Boolean,
    },
    fieldKey: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
