import { defineComponent, ref, reactive, computed } from 'vue'
import HomeInfoForm from '@/components/HomeInfoForm/HomeInfoForm.vue'
import HomeInfoBlock from '@/components/HomeInfoBlock/HomeInfoBlock.vue'
import HomePageForm from '@/components/HomePageForm/HomePageForm.vue'
import { infoBlockConfirmPopupContent } from '@/constants/company'
import type { IInfoBlock, IBlockPage } from '@/types/company'

interface IItemForDelete {
  id: number | null
  pageId?: number | null
  type: 'block' | 'page' | ''
}

interface IPageFormProps {
  blockName: string
  type: 'product' | 'services' | 'sales' | ''
}

export default defineComponent({
  name: 'HomeInfoBlocks',
  components: {
    HomeInfoForm,
    HomeInfoBlock,
    HomePageForm,
  },
  setup() {
    const blocks = ref<IInfoBlock[]>([
      {
        id: 1,
        name: 'Акции',
        canvas: 'sales',
        isActive: true,
        position: 1,
        pages: [],
      },
      {
        id: 2,
        name: 'Популярные товары',
        canvas: 'product',
        isActive: false,
        position: 2,
        pages: [
          {
            id: 1,
            name: 'Альтера терапия',
            isActive: true,
          },
          {
            id: 2,
            name: 'Вакуумный массаж',
            isActive: false,
          },
        ],
      },
      // {
      //   id: 3,
      //   name: 'Актуальные услуги',
      //   canvas: 'services',
      //   isActive: true,
      //   position: 3,
      //   pages: [],
      // },
      {
        id: 4,
        name: 'Новости',
        canvas: 'news',
        isActive: true,
        position: 4,
        pages: [],
      },
    ])
    const selectedBlock = ref<IInfoBlock | null>(null)
    //const overDragBlock = ref<Partial<IInfoBlock> | null>(null)
    const pagePopupProps = ref<IPageFormProps>({
      blockName: '',
      type: '',
    })
    const openedPopup = reactive({
      confirm: false,
      block: false,
      page: false,
      sales: false,
    })
    const itemForDelete = ref<IItemForDelete>({
      id: null,
      type: '',
    })
    const reqError = ref('')

    const pagePopupTitle = computed(
      () => `Добавить товар в блок "${pagePopupProps.value.blockName}"`,
    )

    const confirmPopupContent = computed(() =>
      itemForDelete.value.type
        ? infoBlockConfirmPopupContent[itemForDelete.value.type]
        : '',
    )

    const onOpenInfoForm = (id?: number) => {
      if (id) {
        selectedBlock.value = blocks.value.find((i) => i.id === id) ?? null
      }
      openedPopup.block = true
    }

    const onCloseInfoForm = () => {
      openedPopup.block = false
      if (selectedBlock.value) {
        selectedBlock.value = null
      }
    }

    const onOpenPageForm = (
      type: 'product' | 'services' | 'sales',
      heading?: string,
    ) => {
      if (type === 'sales') {
        openedPopup.sales = true
      } else {
        pagePopupProps.value = {
          blockName: heading ?? '',
          type,
        }
        openedPopup.page = true
      }
    }

    const onClosePageForm = () => {
      openedPopup.page = false
      pagePopupProps.value = {
        blockName: '',
        type: '',
      }
    }

    const openConfirmPopup = () => {
      openedPopup.confirm = true
    }

    const onDeleteBlockBtnClick = (id: number) => {
      itemForDelete.value = {
        id: id,
        type: 'block',
      }
      openConfirmPopup()
    }

    const onDeletePageBtnClick = (blockId: number, pageId: number) => {
      itemForDelete.value = {
        id: blockId,
        pageId,
        type: 'page',
      }
      openConfirmPopup()
    }

    const onCloseConfirmPopup = () => {
      openedPopup.confirm = false
      itemForDelete.value = {
        id: null,
        type: '',
      }
    }

    const onStartDragBlock = (event: DragEvent, id: number) => {
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.effectAllowed = 'move'
        event.dataTransfer.setData('blockId', String(id))
        //event.dataTransfer.setData('blockIndex', String(index))
      }
    }

    // const onDragOverBlock = (
    //   event: DragEvent,
    //   id: number,
    //   position: number,
    // ) => {
    //   console.log(id)
    //   console.log(event.dataTransfer?.getData('blockId'))
    //   const draggableBlockId = event.dataTransfer?.getData('blockId')
    //   if (draggableBlockId && id !== +draggableBlockId) {
    //     overDragBlock.value = { id, position }
    //     return
    //   }
    //   overDragBlock.value = null
    // }

    const onDropBlock = (event: DragEvent, index: number) => {
      const blockId = event.dataTransfer?.getData('blockId')
      if (!blockId) {
        return
      }
      const draggedBlock = blocks.value.find((b) => b.id === +blockId)
      blocks.value = blocks.value.filter((i) => i.id !== +blockId)
      if (draggedBlock) {
        blocks.value.splice(index, 0, draggedBlock)
        blocks.value.forEach((item, index) => (item.position = index + 1))
      }
    }

    return {
      infoBlockConfirmPopupContent,
      blocks,
      selectedBlock,
      openedPopup,
      pagePopupTitle,
      pagePopupProps,
      reqError,
      itemForDelete,
      confirmPopupContent,
      onOpenInfoForm,
      onCloseInfoForm,
      onOpenPageForm,
      onClosePageForm,
      onDeleteBlockBtnClick,
      onDeletePageBtnClick,
      onCloseConfirmPopup,
      onStartDragBlock,
      //onDragOverBlock,
      onDropBlock,
    }
  },
})
