import { defineComponent, onBeforeMount, ref } from 'vue'
import { useCatalogStore } from '@/store/catalog'
import type { PropType } from 'vue'
import type {CatalogListProduct} from '@/types/catalog'
import type { IServices } from '@/types/settings'

type IItemField = CatalogListProduct | IServices | null

export default defineComponent({
  name: 'HomePageForm',
  props: {
    type: {
      type: String as PropType<'product' | 'service'>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props) {
    const selectedItem = ref<IItemField>(null)
    const productOptions = ref<CatalogListProduct[]>([])

    const catalogStore = useCatalogStore()

    const valueUpdate = (key: string, value: CatalogListProduct | IServices) => {
      selectedItem.value = value
    }

    onBeforeMount(async () => {
      if (props.type === 'product') {
        await catalogStore.getProducts()
        if (catalogStore.products.length) {
          productOptions.value = catalogStore.products
        }
      }
    })
    return { productOptions, valueUpdate }
  },
})
