import { useMainStore } from '@/store/main/main'
import { useUIStore } from '@/store/ui/ui'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'PaginationComponent',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
  },
  emits: ['changePage'],
  setup(props, { emit }) {
    const mainStore = useMainStore()
    const UIStore = useUIStore()

    const pagesList = computed(() => {
      if (props.totalPage <= 5) {
        return props.totalPage
      }
      else if (props.currentPage < 2) {
        return [1, 2, '...', props.totalPage - 1, props.totalPage]
      }
      else {
        let arrPages = 0 as (number | string)[] | number

        if (props.totalPage - 5 < props.currentPage) {
          arrPages = Array.from({ length: props.totalPage }, (v, i) => i + 1).slice(-5)
        }
        else {
          arrPages = [props.currentPage, props.currentPage + 1, '...', props.totalPage - 1, props.totalPage]
        }
        return arrPages
      }
    })
    const selectPage = (page: number) => {
      emit('changePage', page)
    }
    return {
      mainStore,
      UIStore,
      selectPage,
      pagesList,
    }
  },
})
