import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'CheckboxElement',
  props: {
    type: {
      type: String as PropType<'checkbox' | 'radio'>,
      default: 'checkbox',
    },
    value: {
      type: [String, Number, Boolean],
    },
    label: {
      type: String,
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    fieldKey: {
      type: String,
    },
  },
  emits: ['update:value'],
  setup() {
    const input = ref<HTMLInputElement | null>(null)
    return { input }
  },
})
