import type { DefaultError } from '@/types/httpError'
import type { Shop, ShopsPayload, ShopsResponse } from '@/types/shops'
import {
  getShopsCall,
} from '@/api/shops'
import { useApiCall } from '@/composables/useApiCall'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'

export const useShopsStore = defineStore('shops', () => {
  const shops = ref<Shop[]>([])
  const metaData = reactive({
    total: 0,
    totalPages: 0,
  })
  const filterFields = reactive({
    page: 1,
    perPage: 15,
  }) as ShopsPayload

  const {
    data: shopsData,
    executeApiCall: shopsAction,
    isLoading: shopsLoading,
    error: shopsError,
  } = useApiCall<ShopsResponse, DefaultError, ShopsPayload>(
    getShopsCall,
    true,
  )

  const isShopsLoading = computed(() => shopsLoading.value)

  const changePage = async (page: number) => {
    if (filterFields.page === page) {
      return
    }
    filterFields.page = page
    await getShops()
  }

  const clearFilter = async () => {
    clearQuery()
    await applyFilter(filterFields)
  }

  const applyFilter = async (filters: ShopsPayload) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key as keyof ShopsPayload] || filters[key as keyof ShopsPayload] === false) {
        filterFields[key as keyof ShopsPayload] = filters[key as keyof ShopsPayload]
      }
      else {
        filterFields[key as keyof ShopsPayload] = ''
      }
    })
    filterFields.page = 1
    await getShops()
  }

  const applySort = async (value: string | null) => {
    if (value) {
      filterFields.sort = value
    }
    else {
      delete filterFields.sort
    }
    filterFields.page = 1
    await getShops()
  }

  const getShops = async () => {
    shops.value = [
      {
        id: 1,
        name: 'Название магазина',
        address: 'Москва, ул. Большая Тульская, д. 46',
        subway: 'Тульская',
        latitude: 37.621049,
        longitude: 55.705101,
        active: true,
        isPickPoint: true,
        isSalePoint: true,
        phone: '+7 999 999 99 99',
      },
      {
        id: 2,
        name: 'Название магазина 2',
        address: 'Москва, ул. Большая Тульская, д. 46',
        subway: 'Тульская',
        latitude: 37.621049,
        longitude: 55.705101,
        active: false,
        isPickPoint: true,
        isSalePoint: true,
        phone: '+7 999 999 99 99',
      },
      {
        id: 2,
        name: 'Название магазина 2',
        address: 'Москва, ул. Большая Тульская, д. 46',
        subway: 'Тульская',
        latitude: 37.621049,
        longitude: 55.705101,
        active: false,
        isPickPoint: false,
        isSalePoint: true,
        phone: '+7 999 999 99 99',
      },
    ]
    metaData.total = 10
    metaData.totalPages = 1
    // try {
    //   const filter = {} as CatalogCategoryPayload
    //   Object.keys(filterFields).forEach((key) => {
    //     if (filterFields[key as keyof CatalogCategoryPayload] || filterFields[key as keyof CatalogCategoryPayload] === false) {
    //       filter[key as keyof CatalogCategoryPayload] = filterFields[key as keyof CatalogCategoryPayload]
    //     }
    //   })
    //   await categoriesAction(filter)
    //   if (categoriesData.value) {
    //     shops.value = [...categoriesData.value.data]
    //     metaData.total = categoriesData.value.pagination.total
    //     metaData.totalPages = categoriesData.value.pagination.totalPages
    //   }
    // }
    // catch {
    //   console.error(categoriesError.value)
    // }
  }

  const clearQuery = () => {
    metaData.totalPages = 0
    metaData.total = 0
    filterFields.page = 1
    filterFields.perPage = 15
    Object.keys(filterFields).forEach(n => ['page', 'perPage'].includes(n) || delete filterFields[n])
  }

  return {
    shops,
    isShopsLoading,
    metaData,
    filterFields,
    changePage,
    clearQuery,
    applyFilter,
    clearFilter,
    getShops,
    applySort,
  }
})
