import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormActionBtns',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reversed: {
      type: Boolean,
    },
  },
  emits: ['cancel'],
  setup() {
    return {}
  },
})
