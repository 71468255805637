import { defineComponent, reactive, ref } from 'vue'
import { useCompanyStore } from '@/store/company'
import { ICompanyMenuSection } from '@/types/company'

interface CompanyMenuFormFields {
  name: string
  canvas: ICompanyMenuSection['canvas']
}

export default defineComponent({
  name: 'CompanyMenuForm',
  emits: ['close'],
  setup(_, { emit }) {
    const companyStore = useCompanyStore()
    const companyMenuItemCanvasOptions = [
      { text: 'Фотогалерея', value: 'photo' },
      { text: 'Текст', value: 'text' },
      { text: 'Сертификат', value: 'cert' },
    ]

    const fields = reactive<CompanyMenuFormFields>({
      name: '',
      canvas: 'text',
    })
    const isLoading = ref(false)

    const onCanvasUpdate = (
      key: keyof typeof fields,
      value: { text: string; value: CompanyMenuFormFields['canvas'] },
    ) => {
      fields[key] = value.value
    }

    const handleSubmit = () => {
      isLoading.value = true
      const body = {
        id: Math.floor(Math.random() * 103434),
        content: fields.canvas === 'text' ? '' : [],
        ...fields,
      }
      if (!companyStore.menuSections) {
        companyStore.menuSections = []
      }
      companyStore.menuSections.push(body)
      isLoading.value = false
      emit('close')
    }
    return {
      companyMenuItemCanvasOptions,
      fields,
      onCanvasUpdate,
      handleSubmit,
      isLoading,
    }
  },
})
