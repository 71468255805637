<template>
  <form class="banner-form" @submit.prevent>
    <div class="banner-form__content">
      <div>
        <h4>Наименование</h4>
        <FormElement
          v-model:value="fields.name"
          placeholder="Введите наименование"
        />
      </div>
      <div>
        <h4>Категория товара</h4>
        <SelectElement
          :value="selectedCategory"
          :options="categories"
          objectKey="category"
          label="text"
          trackBy="value"
          @dataChanged="onUpdateValue"
        />
      </div>
      <div>
        <h4>Фото</h4>
        <LoaderWithPreview
          :preview="fields.photo.preview"
          accept=".jpg,.png"
          id="photo"
          text="Добавить фото"
          secondaryText="1920 px - 1280 px; не более 3 Мб; jpg, png"
          class="banner-form__load"
          @load="onFileUpload"
          @remove="onRemovePhoto"
        />
      </div>
      <CheckboxRadioElement
        :value="!fields.active"
        :checked="fields.active"
        label="Активен"
        fieldKey="active"
        @update:value="onUpdateValue"
      />
      <NotifyComponent v-if="error" :text="error" />
    </div>
    <FormActionBtns
      reversed
      class="banner-form__btns"
      @cancel="$emit('close')"
      >{{ banner ? 'Сохранить  изменения' : 'Создать баннер' }}</FormActionBtns
    >
  </form>
</template>

<script lang="ts" src="./HomeBannerForm.ts"></script>

<style scoped lang="scss" src="./HomeBannerForm.scss"></style>
