<template>
  <div class="select">
    <span v-if="title" class="select__title">{{ title }}</span>
    <Multiselect
      v-bind="$attrs"
      :options="options"
      :option-height="104"
      :placeholder="placeholder"
    >
      <template #noOptions>
        <span>Список пуст</span>
      </template>
      <template #placeholder>
        <span class="select__placeholder">{{ placeholder }}<span v-if="isRequired">*</span></span>
      </template>
      <template v-if="hiddenValue" #selection="{ values, search, isOpen }">
        <span class="multiselect__single"
              v-if="values.length"
              v-show="!isOpen">{{placeholder}}</span>
      </template>

      <template #noResult>
        <span>Не найдено</span>
      </template>
      <template #caret="{ toggle }">
        <SvgIcon
          icon="chevron-down"
          class="caret"
          @mousedown.prevent.stop="toggle"
        />
      </template>
    </Multiselect>
    <div v-if="errorText" class="select__error">
      <span v-for="(error, index) in errorText" :key="index">{{ error }}</span>
    </div>
  </div>
</template>

<script lang="ts" src="./MultiselectElement.ts"></script>

<style lang="scss" src="./MultiselectElement.scss"></style>
