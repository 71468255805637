<template>
  <button type="button" class="file-loader" @click="input ? input.click() : ''">
    <div class="file-loader__wrapper">
      <SvgIcon icon="camera-plus" />
      <p>{{ text }}</p>
      <span>{{ secondaryText }}</span>
    </div>
    <input
      :id="id"
      ref="input"
      type="file"
      :accept="accept"
      @change="(evt) => $emit('fileLoad', evt)"
    >
  </button>
</template>

<script lang="ts" src="./FileLoader.ts"></script>

<style scoped lang="scss" src="./FileLoader.scss"></style>
