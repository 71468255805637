import { defineComponent } from 'vue'
import FileLoader from '@/components/FileLoader/FileLoader.vue'

export default defineComponent({
  name: 'LoaderWithPreview',
  components: {
    FileLoader,
  },
  props: {
    preview: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '.jpg,.png',
    },
    text: {
      type: String,
    },
    secondaryText: {
      type: String,
    },
  },
  emits: ['remove', 'load'],
  setup() {
    return {}
  },
})
