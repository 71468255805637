import { useCatalogStore } from '@/store/catalog'
import { useFilterStore } from '@/store/filter'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'CatalogProductsFilter',
  emits: ['closePopup'],
  setup(_, { emit }) {
    const basePriceFrom = ref<{ name: string, id: number } | null>(null)
    const currentActive = ref<null | boolean>(null)
    const currentAvailability = ref<null | boolean>(null)
    const catalogStore = useCatalogStore()
    const filterStore = useFilterStore()
    const priceOptions = [
      {
        id: '1000',
        name: 'От 1 000',
      },
      {
        id: '3000',
        name: 'От 3 000',
      },
      {
        id: '5000',
        name: 'От 5 000',
      },
      {
        id: '10000',
        name: 'От 10 000',
      },
      {
        id: '12000',
        name: 'От 12 000',
      },
    ]
    const onMainFieldsValueUpdate = (value: { name: string, id: number }) => {
      filterStore.setFilterItem('basePriceFrom', {
        title: 'Базовая цена',
        name: value.name,
        value: value.id,
      })
    }

    const changeActive = (value: boolean, type: string) => {
      if (type === 'active') {
        currentActive.value = value
        filterStore.setFilterItem('active', {
          title: 'Активность',
          name: value ? 'Да' : 'Нет',
          value,
        })
      }
      else {
        currentAvailability.value = value
        filterStore.setFilterItem('byOrder', {
          title: 'Наличие',
          name: value ? 'В наличии' : 'Нет в наличии',
          value,
        })
      }
    }

    const applyFilter = async () => {
      await catalogStore.applyFilter({
        search: filterStore.filters.search,
        active: currentActive.value !== null ? !!filterStore.filters.selected?.active?.value : '',
        byOrder: currentAvailability.value !== null ? !!filterStore.filters.selected?.byOrder?.value : '',
        basePriceFrom: filterStore.filters.selected?.basePriceFrom?.value || '',
      }, 'products')
      filterStore.isSend = false
      emit('closePopup')
    }

    const clearFilter = async () => {
      filterStore.clearFilter()
      await catalogStore.clearFilter('products')
    }

    watch(() => filterStore.filters?.selected?.basePriceFrom, (value, oldValue) => {
      if (oldValue && !value) {
        basePriceFrom.value = null
      }
    }, { deep: true })

    watch(() => filterStore.filters?.selected?.active, (value, oldValue) => {
      if (oldValue && !value) {
        currentActive.value = null
      }
    }, { deep: true })

    watch(() => filterStore.filters?.selected?.byOrder, (value, oldValue) => {
      if (oldValue && !value) {
        currentAvailability.value = null
      }
    }, { deep: true })

    watch(() => filterStore.isSend, async () => {
      if (filterStore.isSend) {
        await applyFilter()
      }
    })

    return {
      catalogStore,
      onMainFieldsValueUpdate,
      filterStore,
      currentActive,
      changeActive,
      clearFilter,
      applyFilter,
      basePriceFrom,
      priceOptions,
      currentAvailability,
    }
  },
})
