import type {
  CatalogCategory,
  CatalogCategoryPayload,
  CatalogDropoutResponse,
  CatalogItemPayload,
  CatalogItemResponse,
  CatalogListProduct,
  CatalogProduct,
  CatalogResponse,
  CatalogSearchPayload,
  EditCategoryPayload,
  EditImageCategoryPayload,
  DeleteStorePayload,
} from '@/types/catalog'
import axios from 'axios'
import {OfferItem} from "@/types/catalog";
import type {DefaultDeletePayload, DeleteSuccessResponse} from "@/types/main";

const getStoreCategoriesCall = async (
  abortController?: AbortController,
  params?: CatalogCategoryPayload,
): Promise<CatalogResponse<CatalogCategory[]>> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/categories', {
    signal: abortController?.signal,
    params,
  })
  return data.data
}

const getStoreCategoryCall = async (
  abortController?: AbortController,
  params?: CatalogItemPayload,
): Promise<CatalogCategory> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/categories/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

const addCategoryCall = async (
  abortController?: AbortController,
  params?: FormData,
): Promise<CatalogCategory> => {
  const { data } = await axios.postForm(
    '/dashboard/v1/chief/market/categories',
    params,
    { signal: abortController?.signal },
  )
  return data
}

const addCategoryImageCall = async (
  abortController?: AbortController,
  params?: EditImageCategoryPayload,
): Promise<CatalogCategory> => {
  const { data } = await axios.postForm(
    `/dashboard/v1/chief/market/categories/${params?.id}`,
    params?.data,
    { signal: abortController?.signal },
  )
  return data
}

const deleteCategoryImageApiCall = async (
  abortController?: AbortController,
  params?: DefaultDeletePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/categories/${params?.id}/image`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}

const deleteStoreItemApiCall = async (
  abortController?: AbortController,
  params?: DeleteStorePayload,
): Promise<DeleteSuccessResponse> => {
  const { data } = await axios.delete(
    `/dashboard/v1/chief/market/${params?.type}/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return data
}


const editCategoryCall = async (
  abortController?: AbortController,
  params?: EditCategoryPayload,
): Promise<CatalogCategory> => {
  const { data } = await axios.put(
    `/dashboard/v1/chief/market/categories/${params?.id}`,
    { ...params?.data },
    { signal: abortController?.signal },
  )
  return data
}


const getStoreSearchCategoriesCall = async (
  abortController?: AbortController,
  params?: CatalogSearchPayload,
): Promise<CatalogDropoutResponse> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/categories/dropout?search=${params?.search}`, {
    signal: abortController?.signal,
  })
  return data.data
}

const getStoreProductsCall = async (
  abortController?: AbortController,
  params?: CatalogCategoryPayload,
): Promise<CatalogResponse<CatalogListProduct[]>> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/products', {
    signal: abortController?.signal,
    params,
  })
  return data.data
}

const getStoreProductCall = async (
  abortController?: AbortController,
  params?: CatalogItemPayload,
): Promise<CatalogProduct> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/products/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

const addProductCall = async (
  abortController?: AbortController,
  params?: any,
): Promise<any> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/market/products',
    {
      ...params,
    },
    { signal: abortController?.signal },
  )
  return data
}

const getStoreProductOffersCall = async (
  abortController?: AbortController,
  params?: CatalogItemPayload,
): Promise<CatalogResponse<OfferItem[]>> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/products/${params?.id}/offers`, {
    signal: abortController?.signal,
  })
  return data.data
}

const getStoreProductOfferCall = async (
  abortController?: AbortController,
  params?: CatalogItemPayload,
): Promise<CatalogItemResponse<CatalogProduct>> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/products/${params?.id}/offers/${91}`, {
    signal: abortController?.signal,
  })
  return data.data
}

export {
  addProductCall,
  getStoreCategoriesCall,
  getStoreCategoryCall,
  getStoreProductCall,
  getStoreProductOfferCall,
  getStoreProductOffersCall,
  getStoreProductsCall,
  getStoreSearchCategoriesCall,
  addCategoryCall,
  editCategoryCall,
  addCategoryImageCall,
  deleteCategoryImageApiCall,
  deleteStoreItemApiCall,
}
