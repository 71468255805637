import type { Filters } from '@/types/main'
import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

export const useFilterStore = defineStore('filter', () => {
  const isOpen = ref(false)
  const isSend = ref(false)
  const filters = reactive({
    search: '',
    selected: null,
  }) as Filters

  const clearFilter = () => {
    filters.selected = null
    filters.search = ''
  }

  const deleteFilterItem = (key: string) => {
    if (filters.selected) {
      delete filters.selected[key]
      isSend.value = true
    }
  }

  const setFilterItem = (key: string, value: { name: string, title: string, value: unknown }) => {
    if (filters.selected) {
      filters.selected[key] = value
    }
    else {
      filters.selected = {}
      filters.selected[key] = value
    }
  }

  const toggleFilter = () => {
    isOpen.value = !isOpen.value
  }

  return {
    isSend,
    filters,
    isOpen,
    clearFilter,
    toggleFilter,
    setFilterItem,
    deleteFilterItem,
  }
})
