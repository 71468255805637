import type {
  CompleteLoginSuccessResponse,
  LoginFormPayload,
  LoginSuccessResponse,
  PasswordResetLinkPayload,
  RegistryFormPayload,
  SMSLoginFormPayload,
  SMSSendPayload,
} from '@/types/auth'
import axios from 'axios'

export const loginAdminApiCall = async (
  abortController?: AbortController,
  params?: LoginFormPayload,
): Promise<LoginSuccessResponse> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/login',
    {
      ...params,
    },
    { signal: abortController?.signal },
  )
  return data
}

export const smsLoginAdminApiCall = async (
  abortController?: AbortController,
  params?: SMSLoginFormPayload,
): Promise<CompleteLoginSuccessResponse> => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/auth/complete',
    {
      ...params,
    },
    { signal: abortController?.signal },
  )
  return data
}

export const registryAdminApiCall = async (
  abortController?: AbortController,
  params?: RegistryFormPayload,
) => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/registration',
    {
      ...params,
    },
    { signal: abortController?.signal },
  )
  return data
}

export const sendSMSAdminApiCall = async (
  abortController?: AbortController,
  params?: SMSSendPayload,
) => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/send-code',
    {
      ...params,
    },
    { signal: abortController?.signal },
  )
  return data
}

export const passwordResetLinkAdminApiCall = async (
  abortController?: AbortController,
  params?: PasswordResetLinkPayload,
) => {
  const { data } = await axios.post(
    '/dashboard/v1/chief/password-reset-link',
    {
      ...params,
    },
    { signal: abortController?.signal },
  )
  return data
}
