import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConfirmPopup',
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ['agree', 'cancel'],
  setup() {
    return {}
  },
})
