<template>
  <form class="page-form" @submit.prevent>
    <div>
      <span class="page-form__label">{{
        type === 'product' ? 'Выберите товар' : 'Выберите услугу'
      }}</span>
      <SelectElement
        :value="productOptions.length ? productOptions[0] : ''"
        :options="productOptions"
        label="title"
        trackBy="id"
        objectKey="item"
        @dataChanged="valueUpdate"
      />
    </div>
    <FormActionBtns
      reversed
      class="page-form__btn-container"
      @cancel="$emit('close')"
      >Добавить</FormActionBtns
    >
  </form>
</template>

<script lang="ts" src="./HomePageForm.ts"></script>

<style scoped lang="scss" src="./HomePageForm.scss"></style>
