<template>
  <ul class="tabs-nav" :class="[{ 'tabs-nav_flat': isStyleFlat }]">
    <li
      v-for="{ title, value, disabled } of items"
      :key="value"
      class="tabs-nav__item"
      :class="[
        {
          'tabs-nav__item_flat': isStyleFlat,
          'selected': selectedTab === value,
        },
      ]"
    >
      <button :disabled="disabled" type="button" @click="onTabClick(value)">
        {{ title }}
      </button>
    </li>
  </ul>
</template>

<script lang="ts" src="./TabsNav.ts"></script>

<style scoped lang="scss" src="./TabsNav.scss"></style>
