import type {
  OrdersItemResponse,
  OrdersPayload,
  OrdersResponse,
} from '@/types/orders'
import type { ItemPayload } from '../types/main'
import axios from 'axios'

const getOrdersCall = async (
  abortController?: AbortController,
  params?: OrdersPayload,
): Promise<OrdersResponse> => {
  const { data } = await axios.get('/dashboard/v1/chief/market/shops', {
    signal: abortController?.signal,
    params,
  })
  return data.data
}

const getOrdersItemCall = async (
  abortController?: AbortController,
  params?: ItemPayload,
): Promise<OrdersItemResponse> => {
  const { data } = await axios.get(`/dashboard/v1/chief/market/shops/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data.data
}

export { getOrdersCall, getOrdersItemCall }
