<template>
  <div class="confirm-popup">
    <slot name="content" />
    <div class="confirm-popup__btn-container">
      <button
        type="button"
        class="confirm-popup__cancel"
        @click="$emit('cancel')"
      >
        <slot name="cancel"> Отмена </slot>
      </button>
      <button
        type="button"
        class="confirm-popup__agree"
        :disabled="isLoading"
        @click="$emit('agree')"
      >
        <Transition>
          <AppLoader v-if="isLoading" />
        </Transition>
        <slot name="agree"> Да, удалить </slot>
      </button>
    </div>
  </div>
</template>

<script lang="ts" src="./ConfirmPopup.ts"></script>

<style scoped lang="scss" src="./ConfirmPopup.scss"></style>
