export const REQUIRED_FIELD_ERROR = 'является обязательным'

export const formRequiredFieldErrorMessage = (field: string) =>
  `Поле "${field}" ${REQUIRED_FIELD_ERROR}`

export const VALIDATION_MESSAGES = {
  password: {
    validationErr:
      'Пароль должен состоять из 8 символов, содержать буквы, цифры и спецсимволы',
  },
  repeatPassword: {
    notSameAsPasswordErr: 'Пароли должны совпадать',
  },
  email: {
    validationErr: 'Поле "E-mail" должно содержать валидный адрес',
  },
  phone: {
    validationErr: 'Поле "Телефон" должно содержать валидный номер',
  },
  file: {
    sizeValidationErr: 'Размер файла превышает установленные значения',
    formatValidationErr: 'Формат изображения не соответствует требованию',
  },
  color: {
    formatValidationErr: 'Цвет должен соответствовать HEX формату',
  },
  inn: {
    validationErr: 'Поле "ИНН" должно содержать от 10 до 12 символов',
  },
}
