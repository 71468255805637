import { useAuthStore } from '@/store/auth/auth'
import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

axios.interceptors.request.use((config) => {
  if (config && config.headers) {
    config.baseURL = BASE_URL
    const authStore = useAuthStore()
    if (authStore.isAuth) {
      config.headers.Authorization = `${authStore.tokenType} ${authStore.token}`
    }
  }
  return config
})

axios.interceptors.response.use(undefined, async (error) => {
  if (
    !axios.isCancel(error) &&
    [401].includes(error?.response?.status)
    // ![USER_LOGIN_COMPLETE_URL, ADMIN_LOGIN_COMPLETE_URL].includes(
    //   error.request.responseURL,
    // )
  ) {
    const authStore = useAuthStore()
    //const refreshToken = localStorage.getItem('refreshToken')
    window.location.href = '/login'
    authStore.clearStore()
  }
  return Promise.reject(error)
})
