<template>
  <div class="catalog-filter">
    <div class="catalog-filter__wrapper">
      <div class="catalog-filter__row">
        <div class="catalog-filter__row-item">
          <span class="catalog-filter__options-title">Статус</span>
          <CheckboxRadioElement
            class="catalog-filter__options-item"
            type="checkbox"
            label="Выполнен"
            value="Выполнен"
            :checked="currentStatus.includes('Выполнен')"
            @update:value="changeStatus('Выполнен')"
          />
          <CheckboxRadioElement
            class="catalog-filter__options-item"
            type="checkbox"
            label="Принят"
            value="Принят"
            :checked="currentStatus.includes('Принят')"
            @update:value="changeStatus('Принят')"
          />
          <CheckboxRadioElement
            class="catalog-filter__options-item"
            type="checkbox"
            label="Отменен"
            value="cancel"
            :checked="currentStatus.includes('Отменен')"
            @update:value="changeStatus('Отменен')"
          />
        </div>
        <div class="catalog-filter__row-item">
          <MultiselectElement
            v-model="client"
            :options="clientOptions"
            label="name"
            title="Клиент"
            :allowEmpty="false"
            objectKey="id"
            trackBy="id"
            placeholder="Выберете клиента"
            class="catalog-filter__select"
            :clear-on-select="false"
            :show-labels="false"
            :close-on-select="true"
            :multiple="false"
            :option-height="104"
            @update:modelValue="onMainFieldsValueUpdate"
          />
        </div>
      </div>
      <div class="catalog-filter__row">
        <div class="catalog-filter__row-item">
          <span class="catalog-filter__options-title">Сумма, ₽</span>
          <div class="catalog-filter__row-wrapper">
            <FormElement
              v-model:value="priceFrom"
              title=""
              tagName="input"
              placeholder="от"
            />
            <span>-</span>
            <FormElement
              v-model:value="priceTo"
              title=""
              tagName="input"
              placeholder="до"
            />
          </div>
        </div>
        <div class="catalog-filter__row-item">
          <span class="catalog-filter__options-title">Период</span>
          <div class="catalog-filter__row-wrapper">
            <FormElement
              v-model:value="dateFrom"
              title=""
              tagName="input"
              placeholder="от"
            />
            <span>-</span>
            <FormElement
              v-model:value="dateTo"
              title=""
              tagName="input"
              placeholder="до"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="catalog-filter__actions">
      <button class="catalog-filter__actions-apply" @click="applyFilter">
        Применить
      </button>
      <button class="catalog-filter__actions-clear" @click="clearFilter">
        Очистить фильтр
      </button>
    </div>
  </div>
</template>

<script lang="ts" src="./OrdersFilter.ts">
</script>

<style scoped lang="scss" src="./OrdersFilter.scss"></style>
