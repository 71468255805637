import MenuComponent from '@/components/MenuComponent/MenuComponent.vue'
import FilterComponent from '@/components/ui/FilterComponent/FilterComponent.vue'
import { useAuthStore } from '@/store/auth/auth'
import { Breakpoints } from '@/enum/Breakpoints'
import { setSessionStorageItem } from '@/helpers/sessionStorage'
import { useUIStore } from '@/store/ui/ui'
import { useCompanyStore } from '@/store/company'
import { onClickOutside } from '@vueuse/core'
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  onBeforeMount,
  ref,
} from 'vue'
import { useRoute } from 'vue-router'
import type { MaybeElementRef } from '@vueuse/core'
import type { MenuItem } from '@/types/lists'

export default defineComponent({
  name: 'MainLayout',
  components: {
    MenuComponent,
    FilterComponent,
  },
  setup() {
    const UIStore = useUIStore()
    const route = useRoute()
    const authStore = useAuthStore()
    const companyStore = useCompanyStore()
    const menuRef = ref<
      (MaybeElementRef & { isConfirmPopupOpen?: boolean }) | null
    >(null)

    const userMenuItems: MenuItem[] = [
      {
        title: 'Личный кабинет',
        route: '/profile',
      },
      {
        title: 'Выйти',
        color: 'red',
        callback: 'handleSignOut',
      },
    ]
    const notebookBreakpoint = Breakpoints.NOTEBOOK

    const handleSignOut = () => {
      // запрос на разлогин
    }
    const isFilter = computed(() =>
      [
        'catalogCategories',
        'catalogKits',
        'catalogProducts',
        'shops',
        'orders',
        'clients',
      ].includes(route.name as string),
    )
    const toggleMenu = () => {
      UIStore.toggleMenu()
      setSessionStorageItem('isMenuOpen', UIStore.getIsMenuOpen)
    }

    const checkMenuVisibility = () => {
      if (window.innerWidth < notebookBreakpoint && UIStore.getIsMenuOpen) {
        toggleMenu()
      }
    }

    onClickOutside(menuRef, () => {
      const hasPopupOpen = (
        el: MaybeElementRef | null,
      ): el is MaybeElementRef & {
        isConfirmPopupOpen: boolean
        isCompanyMenuItemFormOpen: boolean
      } => {
        return (
          el != null &&
          (('isConfirmPopupOpen' in el && (el.isConfirmPopupOpen as boolean)) ||
            ('isCompanyMenuItemFormOpen' in el &&
              (el.isCompanyMenuItemFormOpen as boolean)))
        )
      }
      if (
        window.innerWidth < notebookBreakpoint &&
        menuRef.value &&
        !hasPopupOpen(menuRef.value)
      ) {
        toggleMenu()
      }
    })

    onBeforeMount(() => {
      companyStore.menuSections = [
        {
          id: 1,
          name: 'О компании',
          content: '<p><s>dfdfgdfgdfgddf</s></p>',
          canvas: 'text',
        },
        {
          id: 2,
          name: 'Фотографии',
          content: [
            {
              id: 1,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 2,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 3,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 4,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 5,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 6,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 7,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 8,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 9,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 11,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
            {
              id: 10,
              uuid: '',
              originalUrl: '',
              previewUrl: '',
              collectionName: '',
              url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            },
          ],
          canvas: 'photo',
        },
        {
          id: 3,
          name: 'Лицензии и сертификаты',
          content: [
            {
              id: 343,
              name: 'test',
              description:
                '在1960年代，”Leatraset”公司发布了印刷着Lorem Ipsum段落的纸张，从而广泛普及了它的使用。最近，计算机桌面出版软件”Aldus PageMaker”也通过同样的方式使Lorem Ipsum落入大众的视野。',
              image: {
                id: 10,
                uuid: '',
                originalUrl: '',
                previewUrl:
                  'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                collectionName: '',
                url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
              },
            },
            {
              id: 398989,
              name: 'test',
              description:
                '在1960年代，”Leatraset”公司发布了印刷着Lorem Ipsum段落的纸张，从而广泛普及了它的使用。最近，计算机桌面出版软件”Aldus PageMaker”也通过同样的方式使Lorem Ipsum落入大众的视野。',
              image: {
                id: 10,
                uuid: '',
                originalUrl: '',
                previewUrl:
                  'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                collectionName: '',
                url: 'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
              },
            },
            {
              id: 1,
              name: 'test',
              description:
                '在1960年代，”Leatraset”公司发布了印刷着Lorem Ipsum段落的纸张，从而广泛普及了它的使用。最近，计算机桌面出版软件”Aldus PageMaker”也通过同样的方式使Lorem Ipsum落入大众的视野。',
              image: {
                id: 10,
                uuid: '',
                originalUrl: '',
                previewUrl:
                  'https://plus.unsplash.com/premium_photo-1733514691599-d4a2eb3ca7ca?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                collectionName: '',
                url: '',
              },
            },
          ],
          canvas: 'cert',
        },
      ]
    })

    onMounted(() => {
      window.addEventListener('resize', checkMenuVisibility)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', checkMenuVisibility)
    })

    return {
      UIStore,
      authStore,
      menuRef,
      userMenuItems,
      toggleMenu,
      isFilter,
      handleSignOut,
    }
  },
})
